import React, { useEffect, useState } from 'react';

import Button from '../../../components/button/button_v2';
import Logo from '../../../assets/logo-verde-fundo-branco.png';

import DispGooglePlay from "../../../assets/icons/disponivel-google-play.svg";
import DispAppStore from "../../../assets/icons/disponivel-app-store.svg";
import CompendioCelular from '../../../assets/compendio-celular.png';

import * as S from './styles';


const BlackStepsAndPreview = ({ ...props }) => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <S.ContainerStepsAndPreview>
            <img src={Logo} />
            <h2>Emita seu Receituário Agronômico em apenas 3 passos</h2>

            <S.ContainerPreview>

                <S.ContainerSteps>
                    <S.Steps>
                        <h3>Passo 1</h3>

                        <p>Selecione a <b>Cultura</b>, a <b>Praga</b> e <b>UF</b> de aplicação. Nosso sistema faz a correlação dos dados e exibe apenas os produtos permitidos no seu caso.</p>

                        <h3>Passo 2</h3>

                        <p>Selecione os dados do <b>Responsável Técnico</b> pela emissão das receitas agronômicas.</p>

                        <h3>Passo 3</h3>

                        <p>Selecione o <b>Produtor</b> e o <b>Local de devolução</b> das embalagens vazias e pronto! Você emitiu seu Receituário em menos de 1 minuto.</p>
                    </S.Steps>

                    <S.ContainerDownload>
                        <a href='https://sistema.agroreceita.com.br/cadastro?button=receituario-24-1'>
                            <Button
                                width="180px"
                                height="54px"
                                value="GARANTIR DESCONTO"
                                outline={false}
                            />
                        </a>

                        <S.DownloadApp>
                            <S.ImgGooglePlay>
                                <a href="https://play.google.com/store/apps/details?id=com.clienteagroreceita" target="_blank" rel="noopener noreferrer">
                                    <img src={DispGooglePlay} alt="Disponível no Google Play" />
                                </a>

                                <a className="img-2" href="https://apps.apple.com/us/app/agroreceita-receita-agron%C3%B4mica/id1671808311" target="_blank" rel="noopener noreferrer">
                                    <img src={DispAppStore} alt="Disponível na App Store" />
                                </a>
                            </S.ImgGooglePlay>
                        </S.DownloadApp>
                    </S.ContainerDownload>
                </S.ContainerSteps>
                <S.ContainerPreviewVideo>
                    <iframe
                        src="https://www.youtube.com/embed/EkdFZxWNQak?start=29&autoplay=1&mute=1&controls=0&disablekb=1"
                        title="Emita um Receituário Agronômico em menos de 1 minuto"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />

                    <S.ContainerImage>
                        <img
                            src={CompendioCelular}
                            alt="Imagem de celular"
                            style={{

                                transform: `rotateX(${(mousePosition.y - window.innerHeight / 2) * 0.02}deg) rotateY(${(mousePosition.x - window.innerWidth / 2) * -0.02}deg)`,
                                transition: 'transform 0.2s ease-out',
                            }}
                        />
                    </S.ContainerImage>
                </S.ContainerPreviewVideo>


            </S.ContainerPreview>
        </S.ContainerStepsAndPreview>
    );
}

export default BlackStepsAndPreview;
