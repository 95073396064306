import React, { useState, useEffect } from 'react';
import Button from "../../button/button_v2.js";
import * as S from './styles.js';
import { blackTableData } from './blackTableData.js';
import logo from "../../../assets/logo-verde-fundo-branco.png"
import check from "../../../assets/check-verde.png"
import x from "../../../assets/X-cinza.png"

function BlackPlanTable() {

    const [isMobile, setIsMobile] = useState(false);
    const [positionMiddle, setPositionMiddle] = useState(false);

    return (
        <S.MainContainer>

            <S.Title>Tome melhores decisões com o AgroReceita</S.Title>
            <S.Subtitle>Ideal para Revendas/Cooperativas agrícolas, Agrônomos, Técnicos agrícolas, Fabricantes de defensivos e Produtores rurais.</S.Subtitle>

            <S.ContainerTable>
                <S.Table>
                    <S.TableHead>
                        <S.TableRow>
                            <S.TableHeader><img src={logo} alt='Icone' width="30" className='icon' /></S.TableHeader>
                            <S.TableHeader>Plano Basic</S.TableHeader>
                            <S.TableHeader>Plano Light</S.TableHeader>
                            <S.TableHeader>Plano Pro</S.TableHeader>
                        </S.TableRow>
                    </S.TableHead>
                    <S.TableBody>
                        {blackTableData.map((item, index) => (
                            <S.TableRow key={index}>
                                <S.TableCell>{item.feature}</S.TableCell>
                                {/* Verifica se está na primeira linha */}
                                <S.TableCell>
                                    {index === 0 ? '20 receitas p/ mês' : item.basic ? <img src={check} alt="Certo" width="30" /> : <img src={x} alt="Errado" width="30" />}
                                </S.TableCell>
                                <S.TableCell>
                                    {index === 0 ? 'Ilimitado' : item.light ? <img src={check} alt="Certo" width="30" /> : <img src={x} alt="Errado" width="30" />}
                                </S.TableCell>
                                <S.TableCell>
                                    {index === 0 ? 'Ilimitado' : item.pro ? <img src={check} alt="Certo" width="30" /> : <img src={x} alt="Errado" width="30" />}
                                </S.TableCell>
                            </S.TableRow>
                        ))}

                        <S.SupportRow>
                            <S.TableCell>
                                <S.SupportTitle>Suporte</S.SupportTitle>
                            </S.TableCell>
                            <S.TableCell>
                                <S.SupportPlanName>Suporte Plano Basic</S.SupportPlanName>
                                <S.SupportList>
                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                    <S.SupportItem>E-mail</S.SupportItem>
                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                </S.SupportList>
                            </S.TableCell>
                            <S.TableCell>
                                <S.SupportPlanName>Suporte Plano Light</S.SupportPlanName>
                                <S.SupportList>
                                    <S.SupportItem>Telefone</S.SupportItem>
                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                    <S.SupportItem>E-mail</S.SupportItem>
                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                </S.SupportList>
                            </S.TableCell>
                            <S.TableCell>
                                <S.SupportPlanName>Suporte Plano Pro</S.SupportPlanName>
                                <S.SupportList>
                                    <S.SupportItem>Suporte Prioritário</S.SupportItem>
                                    <S.SupportItem>Telefone</S.SupportItem>
                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                    <S.SupportItem>E-mail</S.SupportItem>
                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                </S.SupportList>
                            </S.TableCell>
                        </S.SupportRow>

                        <S.PriceRow>
                            <S.TableCell>
                                <S.AdditionalText>*Para mais usuários ou receitas, <a href='https://sistema.agroreceita.com.br/simulacaoplano/7'>simule aqui</a>. Quanto mais você contratar, maior é o seu desconto!</S.AdditionalText>
                            </S.TableCell>
                            <S.TableCell>
                                <S.PriceWithButtonContainer>
                                    <S.OldPrice>
                                        <span className="currency-symbol">R$</span><span>79</span>,90
                                    </S.OldPrice>
                                    <S.NewPrice>
                                        <span className="currency-symbol">R$</span><span>55</span><span className="cents">,90*</ span>
                                    </S.NewPrice>
                                    <S.PriceDescription>mensais/usuário</S.PriceDescription>
                                    <a href='#teste-gratis'><Button value='GARANTIR DESCONTO' margin='15px' height='36px' width='186px' /></a>
                                </S.PriceWithButtonContainer>

                            </S.TableCell>
                            <S.TableCell>
                                <S.PriceWithButtonContainer>
                                    <S.OldPrice>
                                        <span className="currency-symbol">R$</span><span>169</span>,90
                                    </S.OldPrice>
                                    <S.NewPrice>
                                        <span className="currency-symbol">R$</span><span>118</span><span className="cents">,90*</ span>
                                    </S.NewPrice>
                                    <S.PriceDescription>mensais/usuário</S.PriceDescription>
                                    <a href='#teste-gratis'><Button value='GARANTIR DESCONTO' margin='15px' height='36px' width='186px' /></a>
                                </S.PriceWithButtonContainer>

                            </S.TableCell>
                            <S.TableCell>
                                <S.PriceWithButtonContainer>
                                    <S.OldPrice>
                                        <span className="currency-symbol">R$</span><span>209</span>,90
                                    </S.OldPrice>
                                    <S.NewPrice>
                                        <span className="currency-symbol">R$</span><span>146</span><span className="cents">,90*</ span>
                                    </S.NewPrice>
                                    <S.PriceDescription>mensais/usuário</S.PriceDescription>
                                    <a href='#teste-gratis'><Button value='GARANTIR DESCONTO' margin='15px' height='36px' width='186px' /></a>
                                </S.PriceWithButtonContainer>

                            </S.TableCell>
                        </S.PriceRow>
                    </S.TableBody>
                </S.Table>
                {/* Nova seção abaixo dos preços */}
                <S.PrePaidPlanContainer>
                    Conheça o <a href='https://agroreceita.com.br/pre-pago/'>Plano Pré-Pago</a> e compre créditos para utilizar quando quiser.
                </S.PrePaidPlanContainer>
            </S.ContainerTable>

            <S.MobilePriceContainer>
                <S.MobilePriceBox>
                    <S.OldPrice isMobile={true}>
                        <span className="currency-symbol">R$</span><span>79</span>,90
                    </S.OldPrice>
                    <S.NewPrice isMobile={true}>
                        <span className="currency-symbol">R$</span><span>55</span>,90
                    </S.NewPrice>
                    <S.PriceDescription isMobile={true}>mensais/usuário</S.PriceDescription>
                </S.MobilePriceBox>

                <S.MobilePriceBox positionMiddle="true">
                    <S.OldPrice isMobile={true}>
                        <span className="currency-symbol">R$</span><span>169</span>,90
                    </S.OldPrice>
                    <S.NewPrice isMobile={true}>
                        <span className="currency-symbol">R$</span><span>118</span>,90
                    </S.NewPrice>
                    <S.PriceDescription isMobile={true}>mensais/usuário</S.PriceDescription>
                </S.MobilePriceBox>

                <S.MobilePriceBox>
                    <S.OldPrice isMobile={true}>
                        <span className="currency-symbol">R$</span><span>209</span>,90
                    </S.OldPrice>
                    <S.NewPrice isMobile={true}>
                        <span className="currency-symbol">R$</span><span>146</span>,90
                    </S.NewPrice>
                    <S.PriceDescription isMobile={true}>mensais/usuário</S.PriceDescription>
                </S.MobilePriceBox>

            </S.MobilePriceContainer>
            {/* <S.ButtonWrapper> */}
            <S.ButtonBelowPrices>
                <a href='#teste-gratis'><Button value='GARANTIR DESCONTO' margin='8px' height='45px' width='230px' /></a>
            </S.ButtonBelowPrices>
            {/* </S.ButtonWrapper> */}

            {/* Texto adicional no mobile */}
            <S.MobileAdditionalTextContainer>
                <S.MobileAdditionalText>
                    *Para mais usuários ou receitas, <a href='https://sistema.agroreceita.com.br/simulacaoplano/7'>simule aqui</a>. Quanto mais você contratar, maior é o seu desconto!
                </S.MobileAdditionalText>
            </S.MobileAdditionalTextContainer>


        </S.MainContainer>
    );
}

export default BlackPlanTable;