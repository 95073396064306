import React from 'react';
import CountUp from 'react-countup';

import { CountData } from './CountData';

import * as S from './styles';


const BlackAnimatedCounter = ({ ...props }) => {

    return (
        <S.Container>
            <S.Wrapper>
                {CountData.map((item) => {
                    const [DescriptionOne, ...rest] = item.Description.split(' ');
                    const DescriptionTwo = rest.join(' ');

                    return (
                        <S.ContainerCouter key={item.id}>
                            <S.InfoNumber>
                                <span>+</span>

                                <CountUp start={item.CountStart} end={item.CountEnd} decimals={item.decimal}/>

                                <span>{item.measure}</span>
                            </S.InfoNumber>

                            <p>{DescriptionOne} <br /> {DescriptionTwo}</p>
                        </S.ContainerCouter>
                    )
                })}
            </S.Wrapper>
        </S.Container>
    );
}

export default BlackAnimatedCounter;
