import seguranca from "../../../assets/seguranca.png"
import mobilidade from "../../../assets/mobilidade.png"
import controle from "../../../assets/controle.png"
import produtividade from "../../../assets/local.png"

export const tableData = [
    {
        id: 1,
        src: seguranca,
        textTitle:'+ Segurança',
        textDescription:'Banco de dados em constante atualização',
    },

    {
        id: 2,
        src: mobilidade,
        textTitle:'+ Mobilidade',
        textDescription:'Emissão do receituário com ou sem internet',
    },

    {
        id: 3,
        src: controle,
        textTitle:'+ Controle',
        textDescription:'Histórico e relatórios',
    },

    {
        id: 4,
        src: produtividade,
        textTitle:'+ Produtividade',
        textDescription:'Preenchimento semi automático dos dados',
    }
]