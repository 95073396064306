import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import 'animate.css';
import Countdown from 'react-countdown';

import IconCloseBlack from '../../assets/icons/icon-close-black.svg';

import * as S from './styles';

export const compendioG = "@compendioG";

const CountdownBlack = ({ onVisibilityChange, ...props }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [isVisible, setIsVisible] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getDiscountText = () => {
        if (width <= 760) {
            return (
                <p>
                    Utilize o cupom <b>AGROBLACK</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro.
                </p>
                
            );
        } else {
            return (
                <p>
                    Utilize o cupom <b>AGROBLACK</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro. Válido para novos clientes até 29/11/2024.
                </p>
            );
        }
    };

    const targetDate = new Date('2024-11-29T23:59:59');


    const handleClose = () => {
        setIsAnimating(true);

        setTimeout(() => {
            setIsVisible(false);
            if (typeof onVisibilityChange === 'function') {
                onVisibilityChange(false);
            }
        }, 500);
    };

    useEffect(() => {
        onVisibilityChange(isVisible);
    }, [isVisible, onVisibilityChange]);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Evento encerrado!</span>;
        } else {
            return (
                <S.ContainerCountdown>
                    <S.ContainerBlock>
                        <span>{days}</span>

                        <span>dias</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{hours}</span>

                        <span>horas</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{minutes}</span>

                        <span>minutos</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{seconds}</span>

                        <span>segundos</span>
                    </S.ContainerBlock>
                </S.ContainerCountdown>
            );
        }
    };

    const headTimerRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Evento encerrado!</span>;
        } else {
            return (
                <S.HeaderContainerCountdown>
                    <S.HeaderContainerBlock>
                        <span>{days}</span>

                        <span>dias</span>
                    </S.HeaderContainerBlock>

                    <S.HeaderContainerBlock>
                        <span>{hours}</span>

                        <span>horas</span>
                    </S.HeaderContainerBlock>

                    <S.HeaderContainerBlock>
                        <span>{minutes}</span>

                        <span>minutos</span>
                    </S.HeaderContainerBlock>

                    <S.HeaderContainerBlock>
                        <span>{seconds}</span>

                        <span>segundos</span>
                    </S.HeaderContainerBlock>
                </S.HeaderContainerCountdown>
            );
        }
    };

    if (!isVisible) return null;

    const containerClass = isAnimating ? 'animate__animated animate__fadeOutUp' : '';

    if (currentPath === "/black-november-2024") {
        return (
            <S.Container className={containerClass}>
                <S.Wrapper>
                    <S.ContainerBlack>
                        <S.InformationBlack>
                            <h1>Black November</h1>

                            <p>Utilize o cupom <b>AGROBLACK</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro.</p>
                         
                            <span>Promoção válida para novos clientes até 29 de novembro de 2024.</span>
                        </S.InformationBlack>

                        <S.CountdownAndButton>
                            <Countdown date={targetDate} renderer={renderer} />

                            <button>
                                <p>Garantir meu desconto</p>
                            </button>
                        </S.CountdownAndButton>
                    </S.ContainerBlack>
                </S.Wrapper>
            </S.Container>
        );
    } else {
        return (
            <S.HeadContainer className={containerClass}>
                <S.HeaderPromo>
                    <S.HeaderTimer>
                        <Countdown date={targetDate} renderer={headTimerRenderer} />
                    </S.HeaderTimer>

                    <S.HeadDescription>
                        {getDiscountText()}
                    </S.HeadDescription>

                    <a href="https://agroreceita.com.br/planos-black-november?utm_source=sistema&utm_medium=contador-display&utm_campaign=black-november-24">
                        <button>
                            <p>Garantir meu desconto</p>
                        </button>
                    </a>

                    <button onClick={handleClose}>
                        <img src={IconCloseBlack} alt="Fechar" />
                    </button>
                </S.HeaderPromo>
            </S.HeadContainer>
        );
    }
};

export default CountdownBlack;