import React, { useEffect, useState } from 'react';

import CadastroSite from '../../cadastro/site';

import BlackNovember from '../../../assets/black-november-left.svg'
import LogoBranca from '../../../assets/logo_hd.svg'

import * as S from './styles'

export const compendioG = "@compendioG";

const BlackRegistrationAndLogin = ({ ...props }) => {
    return (
        <S.Container id='teste-gratis'>
            <S.ContainerFormLogin>
                <S.ContainerInfo>
                    <S.ContainerImage>
                        <img src={LogoBranca} alt="Logo Agroreceita branca" />
                    </S.ContainerImage>
                    <img src={BlackNovember}/>

                    <p>Receituário Agronômico em menos de 1 minuto!</p>

                    <p>Utilize o cupom <b>AGROBLACK</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro.</p>

                    <p>Promoção válida para novos clientes até 29 de novembro de 2024</p>
                </S.ContainerInfo>

                <S.ContainerForm>
                    <CadastroSite />
                </S.ContainerForm>
            </S.ContainerFormLogin>
        </S.Container>
    )
}

export default BlackRegistrationAndLogin