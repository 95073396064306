export const JsonReceita = `
    {
        "empresa": "Revenda Agrícola Exemplo",
        "nome_fantasia": "Revenda Agrícola Exemplo",
        "cnpj": "42.750.893/089",
        "nome_fazenda": "Fazenda Exemplo",
        "cpf_cnpj_fazenda": "28.145.826/2110-00",
        "cep_fazenda": "18.200-100",
        "logradouro_fazenda": "Rua Doutor Jlio Prestes",
        "numero_fazenda": "11",
        "complemento_fazenda": "-",
        "bairro_fazenda": "Centro",
        "cidade_fazenda": "Itapetininga",
        "estado_fazenda": "SP",
        "produtor": "José Eduardo",
        "cpf_cnpj_produtor": "11.258.255/1111-12",
        "num_receita": "4",
        "cultura_nome_comum": "Algodão",
        "cultura_nome_cientifico": "Gossypium hirsutum L.",
        "link_receita_pdf": "https://linkdeexemplo.pdf",
        "data_emissao": "2023-03-08T00:00:00",
        "pragas_nome_comum": "Ácaro-branco",
        "pragas_nome_cientifico": "Polyphagotarsonemus latus",
        "produtos_mapa": "5501",
        "produtos_nome_comercial": "Abamectin Nortox",
        "produtos_nome_tecnico": "Abamectina",
        "produtos_fabricante": "Nortox",
        "produtos_qtd_adquirida": "3",
        "produtos_unidade_quantidade_adquirida": "L",
        "nf": "725"
    }
`;

export const JsonReceitaNativa = `
    {
        "empresa": "Revenda Agrícola Exemplo",
        "nome_fantasia": "Revenda Agrícola Exemplo",
        "numero_art_trt": "9999999999999",
        "cnpj": "42.750.893/089",
        "nome_fazenda": "Fazenda Exemplo",
        "cpf_cnpj_fazenda": "28.145.826/2110-00",
        "cep_fazenda": "18.200-100",
        "logradouro_fazenda": "Rua Doutor Jlio Prestes",
        "numero_fazenda": "11",
        "complemento_fazenda": "-",
        "bairro_fazenda": "Centro",
        "cidade_fazenda": "Itapetininga",
        "estado_fazenda": "SP",
        "produtor": "José Eduardo",
        "cpf_cnpj_produtor": "11.258.255/1111-12",
        "num_receita": "4",
        "cultura_nome_comum": "Algodão",
        "cultura_nome_cientifico": "Gossypium hirsutum L.",
        "link_receita_pdf": "https://linkdeexemplo.pdf",
        "data_emissao": "2023-03-08T00:00:00",
        "pragas": [
            {
                "nome_comum": "Ácaro-branco",
                "nome_cientifico": "Gossypium hirsutum L."
            }
        ],
        "produtos": [
            {
                "mapa": "5501",
                "nome_comercial": "Abamectin Nortox",
                "nome_tecnico": "Abamectina",
                "fabricante": "Nortox",
                "qtd_adquirida": "3",
                "unidade_quantidade_adquirida": "L",
            }
        ]
        "nf": "725"
    }
`;

export const JsonCancelamento = `
    [
        {
            "razão_social": "Nome da empresa",
            "nome_fantasia": "Nome fantasia da empresa",
            "cnpj": "16.516.516\/1651-65",
            "nome_fazenda": "Fazenda",
            "cpf_cnpj_fazenda": "11.258.255\/1111-12",
            "cep_fazenda": "84.185-970",
            "logradouro_fazenda": "Rodovia do Cerne",
            "numero_fazenda": "22",
            "complemento_fazenda": "-",
            "bairro_fazenda": "Centro",
            "cidade_fazenda": "Castro",
            "estado_fazenda": "PR",
            "produtor": "Produtor",
            "cpf_cnpj_produtor": "11.258.255\/1111-12",
            "num_receita": "49",
            "cultura_nome_comum": "Arroz",
            "cultura_nome_cientifico": "Oryza sativa L.",
            "link_receita_pdf": "https://link.com.br/arquivo.pdf",
            "data_emissao": "2023-02-16T00:00:00",
            "pragas": [
                {
                    "nome_comum": "Amendoim-bravo",
                    "nome_cientifico": "Euphorbia heterophylla"
                }
            ],
            "produtos": [
                {
                    "mapa": "6615",
                    "nome_comercial": "2,4-D Amina CCAB 806 SL",
                    "nome_tecnico": "2,4-D",
                    "fabricante": "CCAB Agro",
                    "qtd_adquirida": "10",
                    "unidade_quantidade_adquirida": "L"
                }
            ],
            "cancelado": "true",
        }
    ]
`;

export const JsonProdutor = `
    {
        "empresa_razao_social": "Nome da empresa",
        "empresa_nome_fantasia": "Nome fantasia empresa",
        "empresa_cnpj": "16.516.516\/1651-65"
        "nome_fazenda": "FAZENDA",
        "cpf_cnpj_fazenda": "0205060452282",
        "inscr_estadual_fazenda": "ISENTO",
        "cod_ibge_fazenda": "4100202",
        "cep_fazenda": "41.200-001",
        "logradouro_fazenda": "Rua Frana Martins 09",
        "numero_fazenda": "123",
        "complemento_fazenda": "",
        "bairro_fazenda": "Centro",
        "cidade_fazenda": "Adrianpolis",
        "estado_fazenda": "PR"
        "cpf_cnpj_produtor": "010203040506",
        "telefone_produtor": "(32)1321-2255",
        "celular_produtor": "",
        "cep_produtor": "41.200-001",
        "logradouro_produtor": "Rua Fran Martins 09",
        "numero_produtor": "123",
        "complemento_produtor": "",
        "bairro_produtor": "Centro",
        "cidade_produtor": "Adrianpolis",
        "estado_produtor": "SP",
        "email_produtor": "teste@email.com.br"
    }
`;

export const JsonProdutorNativa = `
    {
        "empresa": {
            "razao_social": "Nome da empresa",
            "nome_fantasia": "Nome fantasia empresa",
            "cnpj": "16.516.516\/1651-65"
        },
        "fazenda": {
            "nome_fazenda": "FAZENDA",
            "cpf_cnpj_fazenda": "0205060452282",
            "inscr_estadual_fazenda": "ISENTO",
            "cod_ibge_fazenda": "4100202",
            "cep_fazenda": "41.200-001",
            "logradouro_fazenda": "Rua Frana Martins 09",
            "numero_fazenda": "123",
            "complemento_fazenda": "",
            "bairro_fazenda": "Centro",
            "cidade_fazenda": "Adrianpolis",
            "estado_fazenda": "PR"
        },
        "produtor": {
            "cpf_cnpj_produtor": "010203040506",
            "telefone_produtor": "(32)1321-2255",
            "celular_produtor": "",
            "cep_produtor": "41.200-001",
            "logradouro_produtor": "Rua Fran Martins 09",
            "numero_produtor": "123",
            "complemento_produtor": "",
            "bairro_produtor": "Centro",
            "cidade_produtor": "Adrianpolis",
            "estado_produtor": "SP",
            "email_produtor": "teste@email.com.br"
        }
    }
`;
