import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  background: transparent;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 900px;
  height: ${props => ( !props.openBox ? props.existInformation ? '167px' : '129px' : 'auto')};
  margin: 20px auto 20px;
  padding: 36px 36px 30px 36px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
  background-color: #FFF;
  border: ${props => (props.erro ? 'solid 1px #ff5252' : ' none')};
  display: flex;
  flex-direction: column;
  justify-content: ${props => (!props.openBox ? 'space-between' : 'flex-start')};
  align-items: center;
  color: ${props => (props.disabled? '#bbbbbb' : '#303848')};
  transition: all 0.2s;

  .row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .arrow {
      transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
      }

      &:active {
        transform: scale(0.8);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    /* width: 100%;
    height: auto;
    margin: 20px 0px 20px;
    padding: 36px 36px 30px 36px; */
  }

  @media only screen and (max-width: 780px) {
    width: 100%;
    height: ${props => ( !props.openBox ? props.existInformation ? 'auto' : 'auto' : 'auto')};
    margin: 0px;
    margin-top: 20px;
    padding: 36px 5% 30px 5%;

    .row {
      justify-content: center;
    }

  }
`;

export const Title = styled.h2`
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => (props.disabled? '#bbbbbb' : '#303848')};

`;

export const Paragraph = styled.p`
  width: 100%;
  margin: 8px 0 0;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${props => (props.disabled? '#bbbbbb' : '#303848')};
`;

export const RowInfo = styled.div`
  width: 100%;
  margin: 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }
`;

export const ColInfo = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ~ div {
    margin-left: 48px;
  }

  @media only screen and (max-width: 780px) {
    ~ div {
      margin-left: 0px !important;
    }    
  }
`;

export const Label = styled.label`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.disabled? '#bbbbbb' : props.erro ? '#ff5252' : '#bac1cf')};
  
  @media only screen and (max-width: 780px) {
    margin-bottom: 0px !important;
  }

`;

export const Texto = styled.p`
  margin: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.disabled? '#bbbbbb' : props.erro ? '#ff5252' : '#303848')};
  height: 17px;

  @media only screen and (max-width: 780px) {
    margin-bottom: 10px !important;
  }

`;
export const ContainerForm = styled.div`
  width: 100%;
  /* max-width: 300px; */
  height: auto;
  /* padding-right: 270px;
  padding-left: 270px; */
  padding-top: 30px;
  margin-top: 36px;
  border-top: 1px solid #e8edf3;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-width: 1024px) {
    /* padding-right: 25%;
    padding-left: 25%; */
  }
  @media only screen and (max-width: 780px) {
    padding-right: 5%;
    padding-left: 5%;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 8px;
    
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  
  .pin{
    width: 12px;
  }
  p{
    margin: 0px;
    margin-left: 8px;

    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a92a2;
    
    span{
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #21262f;
    }
  }
`;

export const ContainerModal = styled.div`
  >div >div {
    width: 30rem;

    @media (max-width: 842px) {
      width: 100%;
    }
  }
`

export const ModalRegistrationCompleted = styled.div`
    width: auto;
    height: auto;

    margin: 0;
    gap: 0.3rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    >img {
      height: 5rem;
    }

    >h2, p, div {
      margin: 0;
    }

    >h2{
      font-weight: 800;
      font-size: 1.5rem;
      font-family: 'Lato';
      line-height: 1.85rem;
    }

    >p{
      margin-bottom: 16px;

      font-weight: 400;
      font-family: 'Lato';
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    >div:nth-of-type(1){
      height: 3.75rem;

      margin-bottom: 10px;

      > input {
          opacity: 0.6; 
          color: #a0a0a0; 
          pointer-events: none; 
          background-color: #f0f0f0; 
      }
    }


  @media (max-width: 1024px) {
    >p{
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
  }

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`