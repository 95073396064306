import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CarouselCommentsData } from '../../components/carousel-comments/CarouselCommentsData';

import ArrowLeft from '../../assets/icons/arrow-left.svg'
import ArrowRight from '../../assets/icons/arrow-right.svg'

import * as S from './styles';


const CarouselComments = ({ ...props }) => {
    const [slidesToShow, setSlidesToShow] = useState(2);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            setWidth(currentWidth);

            if (currentWidth <= 767) {
                setSlidesToShow(1);
            } else {
                setSlidesToShow(2);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [width]);

    const settings = {
        dots: true,
        speed: 1000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        infinite: true,
        prevArrow: <button className="slick-prev"><img src={ArrowLeft} /></button>,
        nextArrow: <button className="slick-next"><img src={ArrowRight} /></button>,
    };


    return (
        <S.Container>
        <S.Wrapper>
            <S.ContainerCarousel className="slider-container">
                <Slider {...settings}>
                  {CarouselCommentsData.map((item) => (
                      <S.ContainerCard key={item.id}>
                      <p>{item.description}</p>

                      <S.ContainerUser>
                          <img src={item.src} alt="" />

                          <S.ContainerUserInfo>
                              <p>{item.name}</p>

                              <p><b>{item.Profession}</b></p>

                              <p>{item.organization}</p>
                          </S.ContainerUserInfo>
                      </S.ContainerUser>
                  </S.ContainerCard>
                  ))}
                </Slider>
            </S.ContainerCarousel>
        </S.Wrapper>
    </S.Container>
    );
}

export default CarouselComments;
