export const tableData = [
    { feature: 'Emitir Receituário Agronômico', basic: true, light: true, pro: true },
    { feature: 'Compêndio Agrícola', basic: true, light: true, pro: true },
    { feature: 'Atualização automática do banco de dados', basic: true, light: true, pro: true },
    { feature: 'Restrição de produtos por estado', basic: true, light: true, pro: true },
    { feature: 'Gerenciar ARTs/TRTs', basic: true, light: true, pro: true },
    { feature: 'Enviar receitas para Defesas Agropecuárias', basic: true, light: true, pro: true },
    { feature: 'Solicitar novos produtos e documentos', basic: true, light: true, pro: true },
    { feature: 'Relatórios e métricas', basic: false, light: true, pro: true },
    { feature: 'Assinatura eletrônica e digital', basic: false, light: true, pro: true },
    { feature: 'Emissão de receitas sem internet', basic: false, light: false, pro: true },
    { feature: 'Emissão de receitas em lote', basic: false, light: false, pro: true },
    { feature: 'Previsão do tempo', basic: false, light: false, pro: true },
    { feature: 'Integração Webhook', basic: false, light: false, pro: true },
    { feature: 'Guia de aplicação', basic: false, light: false, pro: true },
    { feature: 'Cadastrar filiais', basic: false, light: false, pro: true },
    { feature: 'Agenda e CRM', basic: false, light: false, pro: true },
    { feature: 'Emitir FDS (FISPQ) e Fichas de Emergência', basic: false, light: false, pro: true },
];