import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import * as S from './styles';
import BlackRegistrationAndLogin from '../../components/black-november/blackRegistrationandLogin';
import BlackCarousel from '../../components/black-november/blackCarousel';
import BlackStepsAndPreview from '../../components/black-november/blackStepsAndPreview';
import BlackAnimatedCounter from '../../components/black-november/blackAnimatedCounter';
import BlackCarouselComments from '../../components/black-november/blackCarouselComments';
import BlackQualityTable from '../../components/black-november/blackQualityTable';
import BlackPlanTable from '../../components/black-november/blackPlanTable';
import BlackFooter from '../../components/black-november/blackFooter';
import CountdownBlack from '../../components/countdown-black';

export const compendioG = "@compendioG";



const BlackNovember2024 = ({ ...props }) => {
    const [isBlackNovemberVisible, setIsBlackNovemberVisible] = useState(false);

    const handleBlackNovemberVisibility = (isVisible) => {
        setIsBlackNovemberVisible(isVisible);
    };
    
    return (
        <S.Container>
            <S.Wrapper>
                <BlackRegistrationAndLogin />

                <BlackAnimatedCounter />

                <CountdownBlack onVisibilityChange={handleBlackNovemberVisibility} />

                <BlackCarousel />

                <BlackCarouselComments />

                <BlackQualityTable />

                <BlackStepsAndPreview />

                <BlackPlanTable />

                <BlackFooter />
            </S.Wrapper>
        </S.Container>
    );
};

export default withRouter(BlackNovember2024);
