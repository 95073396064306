import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;

    *{
        ::selection{
        color: #21262F;
        background-color: #2FBB69;
        }
    }
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: auto;

    display: flex;
    padding: 1rem 1rem 0;
    align-items: center;
    flex-direction: column;

    background-color: #FFFF;

    @media (max-width: 767px) {
        padding: 1rem 1rem 0;
    }
`;

export const ContainerFooter = styled.div`
    width: 100%;

    margin: 1rem 0.2rem;

    >div{
        border-radius: 1rem;
    }

    >div >div{
        border-radius: 1rem;
    }
;`
