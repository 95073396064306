import React, { useEffect, useState } from 'react';

import { CarouselData } from './CarouselData';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as S from './styles';


const Carousel = ({ ...props }) => {
    const [slidesToShow, setSlidesToShow] = useState(6);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            setWidth(currentWidth);

            if (currentWidth <= 767) {
                setSlidesToShow(3);
            } else if (currentWidth <= 1023) {
                setSlidesToShow(4);
            } else {
                setSlidesToShow(6);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [width]);

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1700,
        autoplaySpeed: 1000,
        cssEase: "ease-in-out"
    };

    return (
        <S.ContainerCarousel className="slider-container">
            <Slider {...settings}>
                {CarouselData.map((item) => (
                    <div key={item.id}>
                        <img src={item.src} alt={item.id} />
                    </div>
                ))}
            </Slider>
        </S.ContainerCarousel>
    );
}

export default Carousel;
