import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    
    *{
        ::selection{
        color: #21262F;
        background-color: #2FBB69;
        }
    }
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #21262f;
`;