import React from 'react';
import Footer from '../../../components/footer';

import * as S from './styles.js';


function BlackFooter() {
    return (
        <S.ContainerFooter>
            <Footer />
        </S.ContainerFooter>
    );
}

export default BlackFooter;
