import clienteMoinhoSertaoForte from '../../assets/logos/cliente-moinho-sertao-forte.webp';
import clienteNovamerica from '../../assets/logos/cliente-novamerica.webp';
import clientePlanteBem from '../../assets/logos/cliente-plante-bem.webp';
import clienteBayer from '../../assets/logos/cliente-bayer.webp';
import clienteFiagril from '../../assets/logos/cliente-fiagril.webp';
import clienteCorporativaAgropecuaria from '../../assets/logos/cliente-corporativa-agropecuaria.webp';
import clienteCacb from '../../assets/logos/cliente-cacb.webp';
import clienteCooperBatata from '../../assets/logos/cliente-cooper-batata.webp';
import clienteVetbr from '../../assets/logos/cliente-vetbr.webp';
import clienteCrestani from '../../assets/logos/cliente-crestani.webp';
import clienteJuriti from '../../assets/logos/cliente-juriti.webp';
import clienteViaSeeds from '../../assets/logos/cliente-via-seeds.webp';
import clienteGarantia from '../../assets/logos/cliente-garantia.webp';
import clienteProcria from '../../assets/logos/cliente-procria.webp';
import clienteFertSeeds from '../../assets/logos/cliente-fert-seeds.webp';
import clienteLavouraSul from '../../assets/logos/cliente-lavoura-sul.webp';
import clienteDkbr from '../../assets/logos/cliente-dkbr.webp';
import clientePantanalAgricola from '../../assets/logos/cliente-pantanal-agricola.webp';
import clienteAgropar from '../../assets/logos/cliente-agropar.webp';
import clienteAgriBoi from '../../assets/logos/cliente-agri-boi.webp';

export const CarouselData = [
  { id: 1, src: clienteMoinhoSertaoForte },
  { id: 2, src: clienteNovamerica },
  { id: 3, src: clientePlanteBem },
  { id: 4, src: clienteBayer },
  { id: 5, src: clienteFiagril },
  { id: 6, src: clienteCorporativaAgropecuaria },
  { id: 7, src: clienteCacb },
  { id: 8, src: clienteCooperBatata },
  { id: 9, src: clienteVetbr },
  { id: 10, src: clienteCrestani },
  { id: 11, src: clienteJuriti },
  { id: 12, src: clienteViaSeeds },
  { id: 13, src: clienteGarantia },
  { id: 14, src: clienteProcria },
  { id: 15, src: clienteFertSeeds },
  { id: 16, src: clienteLavouraSul },
  { id: 17, src: clienteDkbr },
  { id: 18, src: clientePantanalAgricola },
  { id: 19, src: clienteAgropar },
  { id: 20, src: clienteAgriBoi },
];
