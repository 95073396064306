export const CountData = [
    {
        id: 1,
        CountStart: 0,
        CountEnd: 10,
        measure: "k",
        Description: "Receituários emitidos/mês"
    },
    {
        id: 2,
        CountStart: 0,
        CountEnd: 2.7,
        measure: "k",
        Description: "Defensivos disponíveis",
        decimal: 1,
    },
    {
        id: 3,
        CountStart: 0,
        CountEnd: 700,
        Description: "Usuários ativos"
    },
    {
        id: 4,
        CountStart: 0,
        CountEnd: 75,
        measure: "M",
        Description: "Hectares tratados"
    }
]