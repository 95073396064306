import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: auto;
    padding:  2rem;

    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 767px) {
        padding: 1rem 1rem 0;
    }
`;

export const ContainerCarousel = styled.div`
    width: 100%;
    height: auto;
    max-width: 70rem;
    padding: 1.5rem 2.75rem 0.5rem; 

    border-radius: 1rem; 
    background-color: #8A92A217;

    .slick-prev {
        @media (max-width: 479px) { 
            left: -2.1875rem;
        }
    }

    .slick-next {
        @media (max-width: 479px) { 
            right: -2.1875rem; 
        }
    }

    .slick-prev, .slick-next {
        z-index: 1;

        color: #303848;  
        font-size: 1.875rem; 
    }

    .slick-prev:before, .slick-next:before {
        content: ' ';
    }

    .slick-dots{
        bottom: 0;
        position: relative;

        >li{
            margin: 0;
        }

        li button:before {
            opacity: 1;
            color: #303848;
        }

        li.slick-active button:before {
            color: #2FBB69;
        }

        @media (max-width: 767px) {
            margin-top: 0.8125rem;
        }
    }

    @media (max-width: 767px) {
        padding: 1.5rem 2.25rem 0.5rem;
    }

    @media (max-width: 479px) {
        padding: 0 2.25rem 0.5rem; 
    }
`;

export const ContainerCard = styled.div`
    height: auto;
    max-width: 30.3125rem; 
    min-height: 22.8125rem;
    width: 100% !important;
    padding: 1.25rem 1.25rem 1.75rem; 

    gap: 2rem;
    flex-direction: column;
    display: flex !important;
    justify-content: space-between;

    >p {
        color: #FFF;
        font-weight: 700;
        font-size: 1.5rem; 
        line-height: 1.3em;
        font-family: "Lato", Sans-serif;
    }

    @media (max-width: 1024px) {
        min-height: 0;
    }

    @media (max-width: 767px) {
        max-width: 100%;
        padding: 1.25rem 0 0;

        text-align: center;
    }
`;

export const ContainerUser = styled.div`
    width: 100%;
    height: auto;

    gap: 2rem;
    display: flex;
    justify-content: center;

    >img {
        width: 4rem; 
        height: 4rem;
        border-radius: 6.25rem;
    }
`;

export const ContainerUserInfo = styled.div`
    width: auto;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
        text-align: left;
        
        color: #BAC1CF;
        font-weight: 400;
        font-size: 0.75rem; 
        line-height: 1.5em;
        font-family: "Lato", Sans-serif;
    }

    >p:nth-of-type(1) {
        color: #FFF;
        font-size: 1rem; 
        font-weight: 700;
        line-height: 1.6em;
    }
`;