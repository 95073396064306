import styled from "styled-components"

export const ContainerFooter = styled.div`
    width: 100%;

    margin: 1rem 0.2rem;

    >div{
        border-radius: 1rem;
    }

    >div >div{
        border-radius: 1rem;
    }
;`