import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem 0; 

  display: flex;
  justify-content: center;

  @media (max-width: 1024px) { 
    padding: 2rem 0; 
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 1.5rem; 
  max-width: 71.25rem; 

  gap: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  >div:nth-of-type(4) {
    gap: 0.5rem;
  }

  @media (max-width: 1024px) {
    padding: 0 2rem; 
  }

  @media (max-width: 767px) {
    padding: 0;
    
    gap: 1.5rem;
    flex-wrap: wrap;
  }
`;

export const ContainerCouter = styled.div`
  width: 25%;
  height: auto;

  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    color: #FFF;
    font-weight: 400;
    line-height: 1.5em;
    font-size: 0.875rem; 
    font-family: "Lato", Sans-serif;
  }

  @media (max-width: 1024px) {
    gap: 0.625rem; 
    flex-direction: column;

    > p {
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    width: 40%;
  }
`;

export const InfoNumber = styled.div`
  width: auto;
  height: auto;

  display: flex;

  > span {
    color: #FFF;
    font-weight: 700;
    font-size: 2.5rem; 
    line-height: 1.3em;
    font-family: "Lato", Sans-serif;
  }
`;
