import styled from "styled-components";

export const MainContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 3.125rem 0;
    
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-around;

    background-color: #21262F;

    @media (max-width: 750px) {
        flex-direction: column;
    }
`;

export const TextContainer = styled.div`
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    color: white;

    button label{
        font-weight: 900;
    }


    @media (max-width: 750px) {
        margin-bottom: 1.25rem;
    }
`;

export const Heading = styled.h1`
    color: #ffff;
    font-size: 3rem;
    font-weight: 800;
    font-family: 'Lato';
    margin-bottom: 1.25rem;
    
    @media (max-width: 750px) {
        text-align: center;
    }

    @media (max-width: 902px) {
        font-size: 2.5rem;
    }
`;

export const ContainerButton = styled.div`
    @media (max-width: 750px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const ContainerTable = styled.div`
    width: 50%;
    padding: 0.375rem;

    border-radius: 1rem;
    background-color: #242a36;

    @media (max-width: 902px) {
        width: 60%;
    }

    @media (max-width: 750px) {
        width: 100%;
    }
`;

export const Table = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    
    background-color: #242a36;
    
    > div:first-child {
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
    }

    > div:last-child {
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
    }
`;

export const TableRow = styled.div`
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;

    &:nth-child(odd) {
        background-color: #ffffff;
    }

    &:nth-child(even) {
        background-color: #f1f1f1;
    }
    
    &:last-child {
        border-bottom: none;
    }

`;

export const TableCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ImageTable = styled.img`
    width: 1.5rem;
    margin-right: 1rem;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ImageVerify = styled.img`
    width: 1.5rem;
    flex-shrink: 0;
`;

export const TextTitle = styled.div`
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
`;

export const TextDescription = styled.div`
    color: #666;
    font-size: 0.75rem;
`;
