import React, { useState, useEffect, useRef } from 'react';
import api from '../../services/api';
import { withRouter } from "react-router-dom";
import {
    NavBarContainer,
    HeadMenu,
    Items,
    ItemMenu,
    DropSubItems,
    SubItem,
    FooterMenu,
    ItemFootMenu,
    Terms,
    Plans,
    MenuHamburg,
    Notification,
    NumNotifications,
    Upgrade,
    BtnMinimize,
    ButtonNewP,
    ImgGooglePlay
} from "./styles";

import Logo from "../../assets/logo.png";
import DispGooglePlay from "../../assets/icons/disponivel-google-play.svg";
import Welcome from '../../assets/welcome.svg';
import DispAppStore from "../../assets/icons/disponivel-app-store.svg";
import MenuHamburger from "../../assets/icons/menu-hamburger.png";
import Arrow from '../../assets/icons/icons-8-expand-arrow@3x.png';
import IconHome from "../../assets/icons/iconHome";
import IconReceita from "../../assets/icons/iconReceita";
import IconFicha from "../../assets/icons/iconFicha";
import IconGuia from "../../assets/icons/iconGuide";
import IconResponsavel from '../../assets/icons/iconFarmer';
import IconProdutor from '../../assets/icons/iconProdutor';
import IconAdd from '../../assets/icons/iconAdd';
import IconRelatorios from '../../assets/icons/iconRelatorios';
import IconExtra from '../../assets/icons/iconExtra';
import IconBuoy from '../../assets/icons/iconBuoy';
import IconNotification from '../../assets/icons/iconNotification';
import IconBlog from '../../assets/icons/iconBlog';
import IconCrm from '../../assets/icons/iconCrm';
import IconSchedule from '../../assets/icons/iconSchedule';
import Seta from '../../assets/icons/seta';

import ModalTermosUso from '../modal/modalTermos';
import ModalPolitica from '../modal/modalPoliticas';
import ModalTextSession from '../modal/modalText';
import ModalBloqueio from '../modal/modalGeneric';
import ModalGeneric from '../modal/modalGeneric';
import { checkSession, logout, validaIsTest, validateTest } from '../../services/auth';
import Button from '../button/index';
import ButtonV2 from '../button/button_v2';
import Userinfo from './userinfo';
import IconDiamond from '../../assets/icons/diamond';

import Logo1 from '../../assets/logos/1';
import Logo2 from '../../assets/logos/2';
import Logo3 from '../../assets/logos/3';
import Logo4 from '../../assets/logos/4';
import Logo5 from '../../assets/logos/5';
import Logo6 from '../../assets/logos/6';
import Logo7 from '../../assets/logos/7';
import logomini from '../../assets/logo-mini.png'
import { IconClima } from '../../assets/icons/clima';


export const USER = "@userinfo";
export const OPEN = "@open";
export const logged = '@logged';
export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';


const Navbar = ({ isBlackNovember, ...props }) => {

    //Configurações do MENU
    const [ref] = useState(useRef());
    const [hide, setHide] = useState(true);

    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const [pathname, setPathname] = useState('');
    const [allowedScreens, setAllowedScreens] = useState([]);
    const [widthSize, setWidthSize] = useState(0);

    const [planId, setPlanId] = useState(0);
    const [username, setUsername] = useState(0);
    const [isTest, setIsTest] = useState(false);
    const [isPrePago, setIsPrePago] = useState(false);
    const [userPermission, setUserPermission] = useState(0);
    const [userStatusContrato, setUserStatusContrato] = useState('');
    const [userLogado, setUserLogado] = useState('');
    //const [tourOpen, setTourOpen] = useState(false);

    //Configurações de opções do menu
    const [openReceituario, setOpenReceituario] = useState(false);
    const [openResponsavel, setOpenResponsavel] = useState(false);
    const [openProdutor, setOpenProdutor] = useState(false);
    const [openExtras, setOpenExtras] = useState(false);
    const [openUserOptions, setOpenUserOptions] = useState(false);

    //Notificações
    const [qtd_notification, setQtd_notification] = useState(0);

    //Exibição de MODAIS
    const [showModalTermo, setShowModalTermo] = useState(false);
    const [showModalPolitica, setShowModalPolitica] = useState(false);
    const [showModalSession, setShowModalSession] = useState(false);
    const [showModalBloqueio, setShowModalBloqueio] = useState(false);
    const [showModalBloqueioTesteGratis, setShowModalBloqueioTesteGratis] = useState(false);
    const [showModalBloqueioLiberacao, setShowModalBloqueioLiberacao] = useState(false);
    const [showModalBloqueioPlanos, setShowModalBloqueioPlanos] = useState(false);
    const [showModalCancelado, setShowModalCancelado] = useState(false);
    const [showModalHoje, setShowHoje] = useState(false);
    const [showModalVencido, setShowVencido] = useState(false);
    const [showModalLogged, setShowModalLogged] = useState(false);
    //const [tour, setTour] = useState(false);
    const [showModalLoggedTrial, setShowModalLoggedTrial] = useState(false);
    //const [tourIndex, setTourIndex] = useState(0);

    //Configuração de modais de bloqueio
    const [titleModal, setTitleModal] = useState('');
    const [messageModal, setMessageModal] = useState('');
    const [modalItems, setModalItems] = useState([]);

    const [contrato, setContrato] = useState([]);

    const [styleTitleModal] = useState({
        fontFamily: 'Lato',
        fontSize: '22px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        width: '100%',
        textAlign: 'center',
        alignSelf: 'center'
    });

    const [styleTextModal] = useState({
        color: 'rgb(48, 56, 72)',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'center',
        alignSelf: 'center'
    });

    const [fullScreens] = useState([
        'login',
        'login-compra',
        'compra',
        'cadastro',
        'senha',
        'simulacaoplano',
        'compendio-gratis',
        'validador',
        'tour',
        'receituario-agronomico-2024',
        'black-november-2024'
    ])

    const logincompra = /\/login-compra\//g;
    const simulacaocompra = /\/simulacaoplano\//g;


    const redirectTo = (link, id) => {
        // console.log("REDIRECT TO => ", link)
        props.history.push(link);

        if (
            link == '/' ||
            link == '/documentos_seguranca' ||
            link == '/solicitar' ||
            link == '/relatorios'
        ) {
            setOpenReceituario(false);
            setOpenResponsavel(false);
            setOpenProdutor(false);
        }

        setOpenUserOptions(false);
        getNotifications();
    }

    const redirectToBlank = (link) => {
        window.open(link);
    }

    const openMenu = (id) => {
        setIsOpen(true);
        if (id === 1) {
            setOpenReceituario(!openReceituario);
            setOpenResponsavel(false);
            setOpenProdutor(false);
            setOpenExtras(false);
            //setTourIndex(!openReceituario);
        } else if (id == 2) {
            setOpenResponsavel(!openResponsavel);
            setOpenReceituario(false);
            setOpenProdutor(false);
            setOpenExtras(false);
        } else if (id == 3) {
            setOpenProdutor(!openProdutor)
            setOpenReceituario(false);
            setOpenResponsavel(false);
            setOpenExtras(false);
        } else if (id == 4) {
            setOpenExtras(!openExtras);
            setOpenReceituario(false);
            setOpenResponsavel(false);
            setOpenProdutor(false);
        }
    }

    const openModelTerm = () => {
        setShowModalTermo(!showModalTermo);
    }

    const openPolicyTerm = () => {
        setShowModalPolitica(!showModalPolitica);
    }

    const closeAllTabs = (e) => {

        setOpenReceituario(false);
        setOpenResponsavel(false);
        setOpenProdutor(false);
        setOpenExtras(false);

        // console.log("CLOSE ALL TABS => ", e)

        if (isMobile) {
            setIsOpen(false);
        }

        props.isClosed();
    }

    const closeSession = () => {
        setShowModalSession(false);
        window.location = '/login';
    }

    const validateSession = async () => {
        let isValid = await checkSession();
        if (!isValid) {
            setShowModalSession(true);
        }
    }

    const getScreenPermissions = () => {
        let permissions = JSON.parse(localStorage.getItem(USER));

        setUserLogado(permissions);

        if (permissions && permissions !== null) {
            if (permissions.contrato) {
                setContrato(permissions.contrato);

                let plan = permissions.contrato.agro_plan_config.agro_plan_id;
                setPlanId(plan);

                let user = permissions.name;
                setUsername(user);

                permissions = permissions.contrato.agro_plan_permissions;

                let permitidos = [];

                permissions.map((screen) => {
                    if (screen.status === 1) {
                        permitidos = [...permitidos, screen.link];
                    }
                })

                setAllowedScreens(permitidos);
            } else {
                localStorage.removeItem(GOOGLE_EMAIL);
                localStorage.removeItem(GOOGLE_USER);
                localStorage.removeItem(GOOGLE_USER_PROFILE);

                logout();
                window.location = '/login';
            }
        }

    }

    const goToHome = () => {
        setShowModalBloqueio(false);
        setShowModalBloqueioLiberacao(false);
        setShowModalBloqueioTesteGratis(false);
        setShowModalBloqueioPlanos(false);
        setShowModalCancelado(false);
        redirectTo('/', 2);
    }

    const goToPlans = () => {
        setShowModalBloqueioTesteGratis(false);
        setShowModalBloqueio(false);
        setShowModalLoggedTrial(false);
        redirectTo('/planos', 3);
    }

    const openHelp = () => {
        window.open('https://www.agroreceita.com.br/ajuda/', '_blank');
    }

    const validaTesteGratis = async (link, id) => {
        const isValid = await validateTest();

        const isTeste = await validaIsTest();

        if (isTeste) {
            if (isValid) {
                //redirectTo(link, 4);
                let user = JSON.parse(localStorage.getItem(USER));
                let isLogged = localStorage.getItem(logged);

                if (user.contrato.agro_plan_config.agro_plan_id === 1) { //é teste grátis
                    if (isLogged === false || isLogged === 'false') {
                        const open = localStorage.getItem(OPEN);
                        if (open === 'false') {
                            setShowModalLogged(true);
                            localStorage.setItem(OPEN, "true");
                        }
                    } else {
                        if (user.contrato.dias_restantes <= 14 && user.contrato.dias_restantes > 0) {
                            const open = localStorage.getItem(OPEN);
                            if (open === 'false') {
                                setUserLogado(user);
                                setShowModalLoggedTrial(true);
                                localStorage.setItem(OPEN, "true");
                            }
                        }
                    }


                    if (user.contrato.dias_restantes == 0) {
                        //termina hoje
                        setShowHoje(true);

                    } else if (user.contrato.dias_restantes < 0) {
                        //vencido
                        setShowVencido(true);
                    }
                }


            } else {

                if (link === '/receitas/add' || link === '/receitas/addlote') {
                    setTitleModal('Seu teste grátis terminou');
                    setMessageModal(<>
                        <p style={styleTextModal}> Você sabe a importância do receituário agronômico para as lavouras? </p>
                        <p style={styleTextModal}> O receituário é um documento obrigatório que acompanha a compra e venda de defensivos agrícolas e contém recomendações técnicas de como o produto deve ser aplicado. </p>
                        <p style={styleTextModal}> Assine agora o AgroReceita e tenha acesso a emissão de receituários e outros recursos. </p>
                        <p style={styleTextModal}> Saiba mais sobre o <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/receita-agronomica/', '_blank'); }}><u>receituário!</u></span> </p>

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                            <ButtonV2
                                width='296px'
                                height='50px'
                                value='ASSINAR AGORA'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                            />
                        </div>
                    </>);
                    setShowModalBloqueioTesteGratis(true);
                } else if (link === '/receitas/add/' || link === '/receitas/add') {
                    setTitleModal('Seu teste grátis terminou');
                    setMessageModal(<>
                        <p style={styleTextModal}> Você sabe a importância do receituário agronômico para as lavouras? </p>
                        <p style={styleTextModal}> O receituário é um documento obrigatório que acompanha a compra e venda de defensivos agrícolas e contém recomendações técnicas de como o produto deve ser aplicado. </p>
                        <p style={styleTextModal}> Assine agora o AgroReceita e tenha acesso a emissão de receituários e outros recursos. </p>
                        <p style={styleTextModal}> Saiba mais sobre o <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/receita-agronomica/', '_blank'); }}><u>receituário!</u></span> </p>

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                            <ButtonV2
                                width='296px'
                                height='50px'
                                value='ASSINAR AGORA'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                            />
                        </div>
                    </>);
                    setShowModalBloqueioTesteGratis(true);
                } else if (link === '/documentos_seguranca') {
                    setTitleModal('Seu teste grátis terminou');
                    setMessageModal(<>
                        <p style={styleTextModal}> Você sabe qual a importância da FISPQ e da Ficha de Emergência para os defensivos agrícolas?  </p>
                        <p style={styleTextModal}> Toda empresa que comercializa esses produtos devem disponibilizar estes documentos. </p>
                        <p style={styleTextModal}> Assine o plano PRO para desbloquear esse e outros recursos. </p>

                        <div
                            style={{
                                width: '100%',
                                maxWidth: '350px',
                                margin: 'auto',
                                marginTop: '20px',
                                alignSelf: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ButtonV2
                                width='296px'
                                height='50px'
                                value='ASSINAR AGORA'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                            />
                            <span
                                className='bluelink'
                                style={{
                                    color: 'rgb(88, 155, 255)',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    window.open('https://agroreceita.com.br/ficha-emergencia-fispq', '_blank');
                                }}
                            >
                                Saiba mais
                            </span>
                        </div>
                    </>);
                    setShowModalBloqueioTesteGratis(true);
                } else if (link === '/relatorios') {
                    setTitleModal('Seu teste grátis terminou');
                    setMessageModal(<>
                        <p style={styleTextModal}> Tenha em mãos os dados das receitas emitidas por relatórios essenciais para a gestão do seu negócio, como produtos mais comercializados pela sua empresa, monitoramento de alvos mais frequentes por cultura e período, entre outros. </p>
                        <p style={styleTextModal}> Deseja ter utilizar esses relatórios? Ao assinar o plano LIGHT ou PRO você garante esse acesso e muito mais. </p>

                        <p style={styleTextModal}> Saiba mais sobre o <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/relatorios-receituario-agronomico/', '_blank'); }}><u>relatórios!</u></span> </p>

                        <div style={{ marginLeft: '10px', width: '100%', textAlign: 'center', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
                            <ButtonV2
                                width='296px'
                                height='50px'
                                value='ASSINAR AGORA'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                            />
                        </div>
                    </>);
                    setShowModalBloqueioTesteGratis(true);
                } else if (link === '/filiais/add') {
                    setTitleModal('Seu teste grátis terminou');
                    setMessageModal(<>
                        <p style={styleTextModal}> Você possui outras filiais?  </p>
                        <p style={styleTextModal}> Assine o plano PRO para ter acesso ao cadastro de filiais e muito mais, como os recursos abaixo: </p>
                        <p style={styleTextModal}> Emissão de receitas </p>
                        <p style={styleTextModal}> Controle de ARTs/TRTs</p>
                        <p style={styleTextModal}> Relatórios completos </p>
                        <p style={styleTextModal}> Envio de dados para as defesas agropecuárias e CREA SP </p>
                        <p style={styleTextModal}> Cadastro de produtores e locais de aplicação </p>
                        <p style={styleTextModal}> Cadastro de responsáveis técnicos </p>
                        <p style={styleTextModal}> Impressão e consulta de fichas de emergência e FISPQs </p>
                        <p style={styleTextModal}> Está com dúvidas sobre como usar nosso sistema? </p>
                        <p style={styleTextModal}> Agende uma <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/fale-com-especialista/', '_blank'); }}><u>consultoria gratuita!</u></span> </p>

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                            <ButtonV2
                                width='296px'
                                height='50px'
                                value='ASSINAR AGORA'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                            />
                        </div>
                    </>);
                    setShowModalBloqueioTesteGratis(true);
                } else if (link === '/' || link === '/historico' || link === '/agronomos/add' || link === '/arts/add' || link === '/produtores/add/' || link === '/produtores/add' || link === '/devolucoes/add' || link === '/solicitar' || link === '/notificacao' || link === '/guia_aplicacao') {
                    // console.log(props)
                    // alert(link);

                    if (props.location.pathname != '/compra' && props.location.pathname != '/planos' && props.location.pathname.search("/simulacaoplano/") === -1) {
                        setTitleModal('Seu teste grátis terminou!!');
                        setMessageModal(<>
                            <p style={styleTextModal}> Assine o Agroreceita para não perder os recursos abaixo: </p>
                            <p style={styleTextModal}> Emissão de receitas </p>
                            <p style={styleTextModal}> Controle de ARTs/TRTs</p>
                            <p style={styleTextModal}> Relatórios completos </p>
                            <p style={styleTextModal}> Envio de dados para as defesas agropecuárias e CREA SP </p>
                            <p style={styleTextModal}> Cadastro de produtores e locais de aplicação </p>
                            <p style={styleTextModal}> Cadastro de responsáveis técnicos </p>
                            <p style={styleTextModal}> Impressão e consulta de fichas de emergência e FISPQs </p>
                            <p style={styleTextModal}> Está com dúvidas sobre como usar nosso sistema? </p>
                            <p style={styleTextModal}> Agende uma <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/fale-com-especialista/', '_blank'); }}><u>consultoria gratuita!</u></span> </p>

                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                                <ButtonV2
                                    width='296px'
                                    height='50px'
                                    value='ASSINAR AGORA'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => goToPlans()}
                                    loading={false}
                                />
                            </div>
                        </>);
                        setShowModalBloqueioTesteGratis(true);
                    }

                } else if (link != '/login' && props.location.pathname.search("/simulacaoplano/") === -1) {
                    setTitleModal('Seu teste grátis terminou!');
                    setMessageModal(<>
                        <p style={styleTextModal}> Assine o Agroreceita para não perder os recursos abaixo: </p>
                        <p style={styleTextModal}> Emissão de receitas </p>
                        <p style={styleTextModal}> Controle de ARTs/TRTs</p>
                        <p style={styleTextModal}> Relatórios completos </p>
                        <p style={styleTextModal}> Envio de dados para as defesas agropecuárias e CREA SP </p>
                        <p style={styleTextModal}> Cadastro de produtores e locais de aplicação </p>
                        <p style={styleTextModal}> Cadastro de responsáveis técnicos </p>
                        <p style={styleTextModal}> Impressão e consulta de fichas de emergência e FISPQs </p>
                        <p style={styleTextModal}> Está com dúvidas sobre como usar nosso sistema? </p>
                        <p style={styleTextModal}> Agende uma <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/fale-com-especialista/', '_blank'); }}><u>consultoria gratuita!</u></span> </p>

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                            <ButtonV2
                                width='296px'
                                height='50px'
                                value='ASSINAR AGORA'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                            />
                        </div>
                    </>);
                    setShowModalBloqueioTesteGratis(true);
                }
            }
        } else {
            localStorage.setItem(OPEN, "true");
        }

    }

    const validaIsMobile = (width) => {
        if (width > 0) {
            if (width <= 768) {
                setIsMobile(true);
                setIsOpen(false);
                props.updateIsFullScreen(true);
                props.updateIsMobile(true);
            } else {
                setIsMobile(false);
                // setIsOpen(true);
                props.updateIsMobile(false);

                let location0 = props.location.pathname.split('/');

                let location1 = `${location0[1]}`;

                if (fullScreens.find(e => e == location1)) {
                    let user = JSON.parse(localStorage.getItem(USER));

                    if (location1 === 'compra' && user && user !== null) {
                        if (props.location.pathname === '/compra/sucesso') {
                            props.updateIsFullScreen(true);
                        } else {
                            props.updateIsFullScreen(false);
                        }
                    } else {
                        props.updateIsFullScreen(true);
                    }
                } else {
                    props.updateIsFullScreen(false);
                }
            }
        }
    }

    const openMenuByHamburger = () => {
        setIsOpen(true);
    }

    const getNotifications = async () => {

        try {
            const response = await api.get(`/notificacoes/quantidade`);
            const qtdr = response.data.data;

            setQtd_notification(qtdr);
        } catch (error) {

        }
    }

    const getPlansConfig = () => {
        let user = JSON.parse(localStorage.getItem(USER));

        if (user && user !== null) {
            if (user.contrato) {

                if (user.contrato.status) {
                    if (user.contrato.status == 'AP') {
                        let is_test = user.contrato.agro_plan_config.is_teste;
                        let is_prePago = user.contrato.agro_plan_config.is_pre_pago;

                        if (is_test === 1) {
                            setIsTest(true);
                        }

                        if (is_prePago === 1) {
                            setIsPrePago(true);
                        }
                    } else {
                        setIsTest(false);
                    }
                } else {
                    localStorage.removeItem(GOOGLE_EMAIL);
                    localStorage.removeItem(GOOGLE_USER);
                    localStorage.removeItem(GOOGLE_USER_PROFILE);

                    logout();
                    window.location = '/login';
                }
            } else {
                localStorage.removeItem(GOOGLE_EMAIL);
                localStorage.removeItem(GOOGLE_USER);
                localStorage.removeItem(GOOGLE_USER_PROFILE);

                logout();
                window.location = '/login';
            }
        }
    }

    const getUserPermission = () => {
        let user = JSON.parse(localStorage.getItem(USER))
        if (user) {
            setUserPermission(user.agro_permission_id);
        }
    }

    const inicializa = () => {
        const open = localStorage.getItem(OPEN);

        if (open === 'false') {
            validaTesteGratis('/', 1);
        }
    }

    const logs = async (pathname) => {
        let pathnameSplit = pathname.split('/')

        if (pathnameSplit[1] == 'previsao_tempo') {
            if (pathnameSplit[2] == undefined) {
                const response = await api.post(`/logs/add`, {
                    id: 118,
                });
            } else {
                const response = await api.post(`/logs/add`, {
                    id: 135,
                    obs: `Fazenda: ${pathnameSplit[2]}`
                });
            }
        } else if (pathnameSplit[1] == 'relatorios') {
            const response = await api.post(`/logs/add`, {
                id: 119,
            });
        } else if (pathnameSplit[1] == 'agenda') {
            const response = await api.post(`/logs/add`, {
                id: 113,
            });
        } else if (pathnameSplit[1] == 'crm') {
            const response = await api.post(`/logs/add`, {
                id: 136,
            });
        } else if (pathnameSplit[1] == 'integracao') {
            const response = await api.post(`/logs/add`, {
                id: 140,
            });
        }

    }

    useEffect(() => {
        // console.log("RENDER NAVBAR")
        if (props.location.pathname === '/compra/sucesso') {

        } else {
            getScreenPermissions();
            // getNotifications();
            getPlansConfig();

            getUserPermission();

            inicializa();

            let width = document.body.clientWidth + 8;  //pegar largura da tela no inicio
            setWidthSize(width);
        }

    }, [])

    useEffect(() => {
        // console.log("IS OPEN => ", isOpen)
        props.isOpen(isOpen);
    }, [isOpen])

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem(USER));
        if (user) {
            setUserStatusContrato(user.contrato.status);
            if (user.contrato.status === 'AP') {
                if (allowedScreens.length > 0) {
                    let link = props.location.pathname.trim();

                    link = link.split('/');

                    let newLink = '';

                    link.map((l) => {
                        if (l !== '') {
                            if (!isNaN(parseFloat(l)) && isFinite(l)) {
                                newLink = `${newLink}/:id`;
                            } else {
                                newLink = `${newLink}/${l}`;
                            }
                        }
                    });

                    link = newLink ? newLink : '/';

                    if (!allowedScreens.includes(link)) {

                        if (link === '/login' || link === '/compra/sucesso' || link === '/cadastro' || link === '/cadastro/free' || link === '/validador' || link === '/apresentacao' || link === '/receituario-agronomico-2024' || link === '/black-november-2024') {
                            setHide(true);
                            props.updateIsFullScreen(true);
                        } else {

                            setHide(false);

                            if (showModalBloqueioTesteGratis === false && showModalBloqueio === false) {
                                if (!isTest) {
                                    if (props.location.pathname === '/filiais/add') {
                                        setTitleModal('Essa função está disponível apenas no plano PRO.');
                                        setMessageModal(<>
                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Você possui outra filial da sua empresa?
                                            </p>

                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Faça um upgrade para o plano PRO e cadastre essas filiais no AgroReceita.
                                            </p>

                                            <div
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '350px',
                                                    margin: 'auto',
                                                    marginTop: '20px',
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >

                                                <ButtonV2
                                                    width='296px'
                                                    height='50px'
                                                    value='VER PLANOS'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => goToPlans()}
                                                    loading={false}
                                                />
                                            </div>


                                        </>);
                                    } else {
                                        setTitleModal('Essa função não está liberada para o seu plano.');
                                        setMessageModal(<>
                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Faça um upgrade para desbloquear esse recurso
                                            </p>

                                            <div
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '350px',
                                                    margin: 'auto',
                                                    marginTop: '20px',
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >

                                                <ButtonV2
                                                    width='296px'
                                                    height='50px'
                                                    value='VER PLANOS'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => goToPlans()}
                                                    loading={false}
                                                />
                                            </div>
                                        </>);
                                    }
                                    // setHide(false);

                                    setShowModalBloqueio(true);
                                } else {
                                    if (isTest) {
                                        validaTesteGratis(props.location.pathname, 2);
                                    }
                                }
                            }
                        }
                    } else {
                        if (link === '/login' || link === '/compra/sucesso' || link === '/cadastro' || link === '/cadastro/free' || link === '/validador' || link === '/receituario-agronomico-2024' || link === '/black-november-2024') {
                            setHide(true);
                            props.updateIsFullScreen(true);
                        } else {
                            setHide(false);
                            setPathname(props.location.pathname);
                        }
                    }
                } else {
                    setHide(true);
                }

                if (props.closeAllTabs === true) {
                    closeAllTabs(1);
                    setOpenUserOptions(false);
                }
            } else if (user.contrato.status === 'EN') {
                const screensPermitidas = [
                    '/',
                    '/agronomos',
                    '/arts',
                    '/produtores',
                    '/fazendas',
                    '/devolucoes',
                    '/extras/nova_receita',
                    '/usuarios/:id',
                    '/filiais',
                    '/usuarios',
                    '/notificacoes',
                    '/compra/sucesso',
                    '/compendio',
                    '/blog',
                    '/agenda',
                    '/crm',
                    '/login',
                    '/validador'
                ];

                let link = props.location.pathname.trim();
                link = link.split('/');
                let newLink = '';

                link.map((l) => {
                    if (l !== '') {
                        if (!isNaN(parseFloat(l)) && isFinite(l)) {
                            newLink = `${newLink}/:id`;
                        } else {
                            newLink = `${newLink}/${l}`;
                        }
                    }
                });

                link = newLink ? newLink : '/';

                if (!screensPermitidas.includes(link)) {
                    setTitleModal('Aguardando liberação da conta!');
                    setMessageModal('O acesso completo ao sistema estará liberado após a confirmação do pagamento!');
                    setModalItems([]);

                    setShowModalBloqueioLiberacao(true);
                }
            } else if (user.contrato.status === 'CA') {
                const screensPermitidas = [
                    '/',
                    '/agronomos',
                    '/arts',
                    '/produtores',
                    '/fazendas',
                    '/devolucoes',
                    '/extras/nova_receita',
                    '/usuarios/:id',
                    '/filiais',
                    '/usuarios',
                    '/notificacoes',
                    '/compra/sucesso',
                    '/compendio',
                    '/blog',
                    '/agenda',
                    '/crm',
                    '/login',
                    '/validador'
                ];

                let link = props.location.pathname.trim();
                link = link.split('/');
                let newLink = '';

                link.map((l) => {
                    if (l !== '') {
                        if (!isNaN(parseFloat(l)) && isFinite(l)) {
                            newLink = `${newLink}/:id`;
                        } else {
                            newLink = `${newLink}/${l}`;
                        }
                    }
                });

                link = newLink ? newLink : '/';

                if (!screensPermitidas.includes(link)) {
                    setTitleModal('Contrato cancelado!');
                    setMessageModal('Entre em contato com nosso suporte!');
                    setModalItems([]);

                    setShowModalCancelado(true);
                }
            }
        } else {

        }

    }, [props.location.pathname, props.closeAllTabs, allowedScreens.length, planId])

    useEffect(() => {
        setPathname(props.location.pathname);

        if (props.location.pathname !== '/login' && props.location.pathname !== '/compra/sucesso' && props.location.pathname !== '/validador') {
            // validateSession();
            // getNotifications();

            let user = JSON.parse(localStorage.getItem(USER))

            if (user) {
                if (props.location.pathname === '/planos' && userPermission > 1) {
                    if (props.match.isExact === false && props.match.url === '/' && props.match.path === '/' && props.location.pathname !== '/relatorios' && props.location.pathname !== '/solicitar' && props.location.pathname !== '/documentos_seguranca' && props.location.pathname !== '/historico' && props.location.pathname !== '/guia_aplicacao') {
                        setHide(true);
                    } else {
                        if (!isTest) {
                            setTitleModal('Essa função não está liberada para o seu tipo de acesso');
                            setMessageModal('Para atualizar seu plano entre em contato com seu administrador matriz!');
                            setHide(false);

                            setShowModalBloqueioPlanos(true);
                        } else {
                            if (isTest) {
                                validaTesteGratis(props.location.pathname, 3);
                            }
                        }
                    }
                } else {
                    if (allowedScreens.length > 0) {

                        let link = props.location.pathname.trim();

                        link = link.split('/');

                        let newLink = '';

                        link.map((l) => {
                            if (l !== '') {
                                if (!isNaN(parseFloat(l)) && isFinite(l)) {
                                    newLink = `${newLink}/:id`;
                                } else {
                                    newLink = `${newLink}/${l}`;
                                }
                            }
                        });

                        link = newLink ? newLink : '/';


                        if (allowedScreens.includes(link)) {

                        } else {
                            if (props.match.isExact === false && props.match.url === '/' && props.match.path === '/' && props.location.pathname !== '/relatorios' && props.location.pathname !== '/solicitar' && props.location.pathname !== '/documentos_seguranca' && props.location.pathname !== '/historico' && props.location.pathname !== '/validador' && props.location.pathname !== '/guia_aplicacao') {
                                setHide(true);
                            } else {
                                if (!isTest) {
                                    if (props.location.pathname === '/documentos_seguranca') {
                                        setTitleModal('Essa função está disponível apenas no plano PRO');
                                        setMessageModal(<>
                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Você sabe qual a importância da FISPQ e da Ficha de Emergência para os defensivos agrícolas?
                                            </p>

                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Toda empresa que comercializa esses produtos devem disponibilizar estes documentos.
                                            </p>

                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Faça um upgrade para desbloquear esse recurso.
                                            </p>

                                            <div
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '350px',
                                                    margin: 'auto',
                                                    marginTop: '20px',
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >

                                                <ButtonV2
                                                    width='296px'
                                                    height='50px'
                                                    value='VER PLANOS'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => goToPlans()}
                                                    loading={false}
                                                />

                                                <span
                                                    className='bluelink'
                                                    style={{
                                                        color: 'rgb(88, 155, 255)',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        window.open('https://agroreceita.com.br/ficha-emergencia-fispq', '_blank');
                                                    }}
                                                >
                                                    Saiba mais
                                                </span>
                                            </div>


                                        </>);
                                    } else if (props.location.pathname === '/relatorios') {
                                        setTitleModal('Essa função está disponível apenas nos planos LIGHT e PRO.');
                                        setMessageModal(<>
                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Tenha em mãos os dados das receitas emitidas em relatórios fáceis de entender, visualize os produtos mais comercializados pela sua empresa, efetue o monitoramento dos alvos mais frequentes por cultura e período, entre outros.
                                            </p>

                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Faça um upgrade para desbloquear esse recurso.
                                            </p>

                                            <div
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '350px',
                                                    margin: 'auto',
                                                    marginTop: '20px',
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >

                                                <ButtonV2
                                                    width='296px'
                                                    height='50px'
                                                    value='VER PLANOS'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => goToPlans()}
                                                    loading={false}
                                                />

                                                <span
                                                    className='bluelink'
                                                    style={{
                                                        color: 'rgb(88, 155, 255)',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        window.open('https://sudeste-online.s3.amazonaws.com/relatorios/relatorio.png', '_blank');
                                                    }}
                                                >
                                                    Ver exemplo dos relatórios
                                                </span>
                                            </div>


                                        </>);
                                    } else if (props.location.pathname === '/filiais/add') {
                                        setTitleModal('Essa função está disponível apenas no plano PRO.');
                                        setMessageModal(<>
                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Você possui outra filial da sua empresa?
                                            </p>

                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Faça um upgrade para o plano PRO e cadastre essas filiais no AgroReceita.
                                            </p>

                                            <div
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '350px',
                                                    margin: 'auto',
                                                    marginTop: '20px',
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >

                                                <ButtonV2
                                                    width='296px'
                                                    height='50px'
                                                    value='VER PLANOS'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => goToPlans()}
                                                    loading={false}
                                                />
                                            </div>
                                        </>);
                                    } else {
                                        setTitleModal('Essa função não está liberada para o seu plano');
                                        setMessageModal(<>
                                            <p style={{
                                                color: '#303848',
                                                fontFamily: 'Lato',
                                                fontSize: '16px',
                                                fontWeight: 'normal',
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}>
                                                Faça um upgrade para desbloquear esse recurso
                                            </p>

                                            <div
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '350px',
                                                    margin: 'auto',
                                                    marginTop: '20px',
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <ButtonV2
                                                    width='296px'
                                                    height='50px'
                                                    value='VER PLANOS'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => goToPlans()}
                                                    loading={false}
                                                />
                                            </div>
                                        </>);
                                    }
                                    setHide(false);

                                    setShowModalBloqueio(true);

                                } else {
                                    if (isTest) {
                                        validaTesteGratis(props.location.pathname, 4);
                                    }
                                }
                            }
                        }
                    }
                }
            }


        } else {
            setOpenUserOptions(false); //fechar opções de usuário
            setHide(true);
            props.updateIsFullScreen(true);
        }

        if (isMobile) {
            setIsOpen(false);
        }
    }, [props.location.pathname, userPermission])

    useEffect(() => {
        if (pathname && pathname !== '/') {
            validateSession();
        }
    }, [pathname])

    useEffect(() => {
        validaIsMobile(widthSize);
    }, [widthSize])

    useEffect(() => {
        if (props.routeConfig !== [] && props.routeConfig.length !== 0) {
            setHide(!props.routeConfig.showMenu);
        }
    }, [props.routeConfig])

    useEffect(() => {
        // console.log(qtd_notification)
        if (qtd_notification > 0) {
            let context = new AudioContext(),
                oscillator = context.createOscillator(),
                contextGain = context.createGain();

            oscillator.connect(contextGain);
            contextGain.connect(context.destination);
            oscillator.start(0);

            setTimeout(() => {
                contextGain.gain.exponentialRampToValueAtTime(
                    0.00001, context.currentTime + 0.04
                )
                let context2 = new AudioContext(),
                    oscillator2 = context2.createOscillator(),
                    contextGain2 = context2.createGain();

                oscillator2.connect(contextGain2);
                contextGain2.connect(context2.destination);
                oscillator2.start(0);

                setTimeout(() => {
                    contextGain2.gain.exponentialRampToValueAtTime(
                        0.00001, context2.currentTime + 0.04
                    )
                }, 100);

            }, 50);




        }
    }, [qtd_notification])

    useEffect(() => {
        if (props.location.pathname !== '/' && isMobile === false) {
            // console.log('SETAR FALSO')
            setIsOpen(false);
            closeAllTabs(2);

            logs(props.location.pathname, 0)
        } else if (props.location.pathname === '/' && isMobile === false) {
            setIsOpen(true);
        }
    }, [props.location.pathname, isMobile])


    useEffect(() => {
        // console.log("HIDE => ", hide)
    }, [hide])

    //função para capturar evento de movimentar tamanho da janela
    window.onresize = function (event) {
        let width = document.body.clientWidth + 8;
        setWidthSize(width);
    }

    const minimizeMenu = () => {
        setIsOpen(!isOpen);
        closeAllTabs(3);
    }

    return (
        <>

            {isMobile === false && hide === false && (
                <BtnMinimize onClick={() => minimizeMenu()} isOpen={isOpen}>
                    <Seta open={isOpen} />
                </BtnMinimize>
            )}

            <NavBarContainer
                ref={ref}
                hide={hide}
                isMobile={isMobile}
                isOpen={isOpen}
            >
                {console.log("isMobile = ", isMobile)}
                {console.log("hide = ", hide)}
                {console.log("planId = ", planId)}
                {console.log("------")}

                {isMobile === true && hide === false ? (
                    <HeadMenu button={isTest || isPrePago}>
                        <div className='line-2'>
                            {isMobile && (
                                planId === 1 ? (
                                    <Logo1 width={150} style={{ maxWidth: '136px' }} onClick={() => window.location = '/planos'} />
                                ) : planId === 6 && (
                                    <Logo6 width={150} style={{ maxWidth: '136px' }} onClick={() => window.location = '/planos'} />
                                )
                            )}
                        </div>

                        <div className='line-1'>
                            <Userinfo
                                isBlackNovember={isBlackNovember}
                                isMobile={isMobile}
                                planId={planId}
                                showOptions={openUserOptions}
                                updateOpenUserOptions={(e) => setOpenUserOptions(e)}
                                redirectTo={(e) => redirectTo(e, 5)}
                                location={props.location.pathname}
                                button={isTest || isPrePago}
                            />
                            <Plans
                                isMobile={isMobile}
                                show={
                                    isTest ?
                                        props.location.pathname === '/login' ?
                                            false : props.location.pathname === '/compra/sucesso' ?
                                                false : props.location.pathname.search(logincompra) !== -1 ?
                                                    false : props.location.pathname.search(simulacaocompra) !== -1 ?
                                                        false : true
                                        : false
                                }
                                onClick={() => redirectTo('/planos', 6)}
                            >
                                ASSINE AGORA
                            </Plans>

                            <Plans
                                isMobile={isMobile}
                                style={{
                                    marginLeft: '-10px'
                                }}
                                show={
                                    isPrePago ?
                                        props.location.pathname === '/login' ?
                                            false : props.location.pathname === '/compra/sucesso' ?
                                                false : props.location.pathname.search(logincompra) !== -1 ?
                                                    false : props.location.pathname.search(simulacaocompra) !== -1 ?
                                                        false : true
                                        : false
                                }
                                onClick={() => redirectTo('/comprar_receitas', 7)}
                            >
                                COMPRAR RECEITAS
                            </Plans>

                            {
                                planId === 4 ? (
                                    <Logo4 width={150} style={{ maxWidth: '136px' }} onClick={() => window.location = '/planos'} />
                                ) : planId === 5 ? (
                                    <Logo5 width={150} style={{ maxWidth: '136px' }} />
                                ) : planId === 7 ? (
                                    <Logo7 width={150} style={{ maxWidth: '136px' }} onClick={() => window.location = '/planos'} />
                                ) : (null)
                            }
                        </div>

                    </HeadMenu>
                ) : (
                    <>
                        {hide === true ? (null) : (
                            <HeadMenu isOpenMenu={isOpen}>
                                <div onClick={() => goToHome()}>
                                    {isOpen ? (
                                        <>
                                            {userStatusContrato === 'EN' ? (
                                                <img src={Logo} alt="logo" onClick={() => redirectTo("/", 8)} />
                                            ) : (planId === 1 ? (
                                                <Logo1 width={200} />
                                            ) : (planId === 2 ? (
                                                <Logo2 width={200} />
                                            ) : (planId === 3 ? (
                                                <Logo3 width={200} />
                                            ) : (planId === 4 ? (
                                                <Logo4 width={200} />
                                            ) : (planId === 5 ? (
                                                <Logo5 width={200} />
                                            ) : (planId === 6 ? (
                                                <Logo6 width={200} />
                                            ) : (planId === 7 ? (
                                                <Logo7 width={200} />
                                            ) : (
                                                null
                                            ))))))))}
                                        </>
                                    ) : (
                                        <img src={logomini} width='33px' height='33px' alt='Logo Agroreceita' />
                                    )}
                                </div>
                            </HeadMenu>
                        )}
                    </>
                )}
                <Items>
                    {/* --- HOME --- */}
                    <ItemMenu
                        active={pathname === '/' ? true : false}
                        onClick={() => redirectTo('/', 2)}
                        isOpenMenu={isOpen}

                    >
                        <IconHome />
                        {isOpen && (
                            <>
                                <label>INÍCIO</label>
                            </>
                        )}

                        <p>Início</p>
                    </ItemMenu>

                    {/* --- RECEITUARIO --- */}
                    <ItemMenu
                        active={pathname === '/receitas/add' || pathname === '/receitas/addlote' || pathname === '/compendio' || pathname === '/historico' || pathname === '/guia_aplicacao' ? true : openReceituario ? true : false}
                        onClick={() => openMenu(1)}
                        isOpen={openReceituario}
                        className="receituario"
                        isOpenMenu={isOpen}
                    >
                        <IconReceita />
                        {isOpen && (
                            <>
                                <label>RECEITUÁRIO</label>
                                <img src={Arrow} alt='seta' className='arrow' />
                            </>
                        )}
                        <p>Receituário</p>
                    </ItemMenu>

                    <DropSubItems open={pathname === '/receitas/add' || pathname === '/receitas/addlote' || pathname === '/compendio' || pathname === '/historico' || pathname === '/guia_aplicacao' ? true : openReceituario ? true : false}>
                        <SubItem
                            open={pathname === '/receitas/add' ? openReceituario ? true : false : openReceituario ? true : false}
                            active={pathname === '/receitas/add' ? true : false}
                            onClick={() => { redirectTo('/receitas/add') }}
                            className="emitirR"
                        >
                            <label>EMITIR RECEITA</label>
                        </SubItem>

                        <SubItem
                            open={pathname === '/receitas/addlote' ? openReceituario ? true : false : openReceituario ? true : false}
                            active={pathname === '/receitas/addlote' ? true : false}
                            onClick={() => { redirectTo('/receitas/addlote') }}
                            className="emitirR"
                        >
                            <label>EMITIR RECEITA EM LOTE</label>
                        </SubItem>

                        <SubItem
                            open={pathname === '/compendio' ? openReceituario ? true : false : openReceituario ? true : false}
                            active={pathname === '/compendio' ? true : false}
                            onClick={() => redirectTo('/compendio')}
                        >
                            <label>COMPÊNDIO</label>
                        </SubItem>

                        <SubItem
                            open={pathname === '/historico' ? openReceituario ? true : false : openReceituario ? true : false}
                            active={pathname === '/historico' ? true : false}
                            onClick={() => redirectTo('/historico')}

                        >
                            <label>HISTÓRICO</label>
                        </SubItem>

                        <SubItem
                            open={pathname === '/solicitar' ? openReceituario ? true : false : openReceituario ? true : false}
                            active={pathname === '/solicitar' ? true : false}
                            onClick={() => redirectTo('/solicitar')}
                        >
                            <label>SOLICITAR PRODUTO</label>
                        </SubItem>

                        <SubItem
                            open={pathname === '/guia_aplicacao' ? openReceituario ? true : false : openReceituario ? true : false}
                            active={pathname === '/guia_aplicacao' ? true : false}
                            onClick={() => redirectTo('/guia_aplicacao')}
                        >
                            <label>GUIA DE APLICAÇÃO</label>
                        </SubItem>

                        <SubItem
                            open={pathname === '/relatorios' ? openReceituario ? true : false : openReceituario ? true : false}
                            active={pathname === '/relatorios' ? true : false}
                            onClick={() => redirectTo('/relatorios')}
                        >
                            <label>RELATÓRIOS</label>
                        </SubItem>

                    </DropSubItems>

                    {/* --- RESPONSAVEIS TECNICOS --- */}
                    <ItemMenu
                        isOpenMenu={isOpen}
                        active={
                            pathname === '/agronomos' ||
                                pathname === '/agronomos/:id' ||
                                pathname === '/agronomos/add' ||
                                pathname === '/arts' ||
                                pathname === '/arts/:id' ||
                                pathname === '/arts/add' ||
                                pathname === '/contracts/:id' ||
                                pathname === '/editcontract/:id' ||
                                pathname === '/newcontract/:id' ? true : openResponsavel ? true : false
                        }
                        onClick={() => openMenu(2)}
                        isOpen={openResponsavel}
                    >
                        <IconResponsavel
                            width='22px'
                        />
                        {isOpen && (
                            <>
                                <label>RESPONSÁVEIS TÉCNICOS</label>
                                <img src={Arrow} alt='seta' className='arrow' />
                            </>
                        )}
                        <p>Responsáveis Técnicos</p>
                    </ItemMenu>

                    <DropSubItems open={openResponsavel}>
                        <SubItem
                            open={openResponsavel}
                            active={
                                pathname === '/agronomos' ||
                                    pathname === '/agronomos/:id' ||
                                    pathname === '/agronomos/add' ? true : false
                            }
                            onClick={() => redirectTo('/agronomos')}
                        >
                            <label>RESPONSÁVEIS TÉCNICOS</label>
                        </SubItem>

                        <SubItem
                            open={openResponsavel}
                            active={
                                pathname === '/arts' ||
                                    pathname === '/arts/:id' ||
                                    pathname === '/arts/add' ||
                                    pathname === '/contracts/:id' ||
                                    pathname === '/editcontract/:id' ||
                                    pathname === '/newcontract/:id' ? true : false
                            }
                            onClick={() => redirectTo('/arts')}
                        >
                            <label>ARTs/TRT</label>
                        </SubItem>
                    </DropSubItems>


                    {/* --- PRODUTORES --- */}
                    <ItemMenu
                        isOpenMenu={isOpen}
                        active={
                            pathname === '/produtores' ||
                                pathname === '/produtores/:id' ||
                                pathname === '/produtores/add' ? true : openProdutor ? true : false
                        }
                        onClick={() => openMenu(3)}
                        isOpen={openProdutor}
                    >
                        <IconProdutor />
                        {isOpen && (
                            <>
                                <label>PRODUTORES</label>
                                <img src={Arrow} alt='seta' className='arrow' />
                            </>
                        )}
                        <p>Produtores</p>
                    </ItemMenu>

                    <DropSubItems open={openProdutor}>
                        <SubItem
                            open={openProdutor}
                            active={
                                pathname === '/produtores' ||
                                    pathname === '/produtores/:id' ||
                                    pathname === '/produtores/add' ? true : false
                            }
                            onClick={() => redirectTo('/produtores')}
                        >
                            <label>PRODUTORES</label>
                        </SubItem>

                        {/* <SubItem 
                            open={openProdutor}
                            active={
                                pathname === '/fazendas' || 
                                pathname === '/fazendas/:idfazenda/:idprodutor' || 
                                pathname === '/localdeaplicacao/add/:id' ? true : false
                            }
                            onClick={() => redirectTo('/fazendas')}
                        >
                            <label>LOCAIS DE APLICAÇÃO</label>
                        </SubItem> */}

                        <SubItem
                            open={openProdutor}
                            active={
                                pathname === '/devolucoes' ||
                                    pathname === '/devolucoes/:id' ||
                                    pathname === '/devolucoes/add' ? true : false
                            }
                            onClick={() => redirectTo('/devolucoes')}
                        >
                            <label>LOCAIS DE DEVOLUÇÃO</label>
                        </SubItem>

                        <SubItem
                            // open={pathname === '/previsao_tempo' ? true : false}
                            open={openProdutor}
                            active={pathname === '/previsao_tempo' ? true : false}
                            onClick={() => redirectTo('/previsao_tempo')}
                        >
                            {/* <IconClima width="24" /> */}
                            {/* <IconClima width="14" /> */}
                            <label>PREVISÃO DO TEMPO</label>
                        </SubItem>
                    </DropSubItems>


                    {/* --- AGENDA --- */}
                    <ItemMenu
                        isOpenMenu={isOpen}
                        active={pathname === '/agenda' ? true : false}
                        onClick={() => redirectTo('/agenda')}
                    >
                        <IconSchedule
                            width='22px'
                        />
                        {isOpen && (
                            <>
                                <label>AGENDA</label>
                            </>
                        )}
                        <p>Agenda</p>
                    </ItemMenu>

                    {/* --- CRM --- */}
                    <ItemMenu
                        isOpenMenu={isOpen}
                        active={pathname === '/crm' ? true : false}
                        onClick={() => redirectTo('/crm')}
                    >
                        <IconCrm />
                        {isOpen && (
                            <>
                                <label>OPORTUNIDADES NEGÓCIOS</label>
                            </>
                        )}
                        <p>Oportunidades Negócios</p>
                    </ItemMenu>

                    {/* --- FISPQ + FE --- */}
                    <ItemMenu
                        isOpenMenu={isOpen}
                        active={pathname === '/documentos_seguranca' ? true : false}
                        onClick={() => redirectTo('/documentos_seguranca')}
                    >
                        <IconFicha />
                        {isOpen && (
                            <>
                                <label>EMITIR FISPQ E FE</label>
                            </>
                        )}
                        <p>Emitir FISPQ e FE</p>
                    </ItemMenu>


                    {/* --- EXTRAS --- */}
                    <ItemMenu
                        isOpenMenu={isOpen}
                        active={
                            pathname === '/extras/nova_receita' ? true : openExtras ? true : false
                        }
                        onClick={() => openMenu(4)}
                        isOpen={openExtras}
                    >
                        <IconExtra />
                        {isOpen && (
                            <>
                                <label>EXTRAS</label>
                                <img src={Arrow} alt='seta' className='arrow' />
                            </>
                        )}
                        <p>Extras</p>
                    </ItemMenu>

                    <DropSubItems open={openExtras}>

                        <SubItem
                            open={openExtras}
                            active={pathname === '/blog' ? true : false}
                            onClick={() => redirectTo('/blog')}
                        >
                            {/* <IconBlog /> */}
                            <label>BLOG AGRORECEITA</label>
                        </SubItem>

                        <SubItem
                            open={openExtras}
                            active={false}
                            onClick={() => redirectToBlank('https://sudeste-online.s3.amazonaws.com/ra-extras/Check%20List%20Receitu%C3%A1rio.pdf')}
                        >
                            <label>CHECK LIST DA RECEITA</label>
                        </SubItem>

                        <SubItem
                            open={openExtras}
                            active={false}
                            onClick={() => redirectToBlank('https://sudeste-online.s3.amazonaws.com/ra-extras/Infogr%C3%A1fico%20T%C3%A9cnico%20Agr%C3%ADcola.pdf')}
                        >
                            <label>TÉCNICO AGRÍCOLA</label>
                        </SubItem>

                        <SubItem
                            open={openExtras}
                            active={
                                pathname === '/extras/nova_receita' ? true : false
                            }
                            onClick={() => redirectTo('/extras/nova_receita')}
                        >
                            <label>NOVA EMISSÃO DE RECEITA</label>
                        </SubItem>
                    </DropSubItems>

                    {isOpen ? (
                        <ImgGooglePlay >
                            <a href='https://play.google.com/store/apps/details?id=com.clienteagroreceita' target='_blank'>
                                <img src={DispGooglePlay} />
                            </a>
                            <a className='img-2' href='https://apps.apple.com/us/app/agroreceita-receita-agron%C3%B4mica/id1671808311' target='_blank'>
                                <img src={DispAppStore} />
                            </a>
                        </ImgGooglePlay>
                    ) : (
                        null
                    )}

                </Items>

                <FooterMenu isOpenMenu={isOpen}>
                    {/* --- AJUDA --- */}
                    <ItemFootMenu
                        active={false}
                        onClick={() => redirectToBlank('https://agroreceita.com.br/central-de-ajuda/')}
                        style={{
                            backgroundColor: "#1D2129"
                        }}
                        isOpenMenu={isOpen}
                    >
                        <IconBuoy />
                        {isOpen && (
                            <>
                                <label>CENTRAL DE AJUDA</label>
                            </>
                        )}

                        <p>Central de ajuda</p>

                    </ItemFootMenu>

                    {isOpen && (
                        <>
                            <Terms>
                                <label onClick={() => openModelTerm()}>Termos de uso</label>
                                <label onClick={() => openPolicyTerm()}>Politica de privacidade</label>
                            </Terms>
                        </>
                    )}

                </FooterMenu>
            </NavBarContainer>

            {isMobile === true && hide === false ? (
                <>
                    {isOpen === false ? (
                        <MenuHamburg 
                        isBlackNovember={isBlackNovember}
                        onClick={() => openMenuByHamburger()} style={{
                            display: props.location.pathname === '/login' ? 'none' : props.location.pathname === '/compra/sucesso' ? 'none' : 'flex'
                        }}>
                            <img src={MenuHamburger} alt='menu' onClick={() => openMenuByHamburger()} />
                        </MenuHamburg>


                    ) : (null)}
                </>
            ) : (
                <>
                    {hide === false ? (
                        <>

                            <ButtonNewP
                                isBlackNovember={isBlackNovember}
                                onClick={() => window.location = "/receitas/add"}
                                show={
                                    props.location.pathname === '/' ? true : false
                                }
                                isPro={planId == 5 ? true : false}
                                isTest={planId == 1 ? true : false}
                            >
                                <p style={{ cursor: 'pointer' }}>NOVA RECEITA</p>
                            </ButtonNewP>

                            <Upgrade
                              isBlackNovember={isBlackNovember}
                                show={props.location.pathname === '/login' ?
                                    false : props.location.pathname === '/compra/sucesso' ?
                                        false : props.location.pathname.search(logincompra) !== -1 ?
                                            false : props.location.pathname.search(simulacaocompra) !== -1 ?
                                                false : isTest ?
                                                    false : planId == 5 ?
                                                        false : true
                                }
                                onClick={() => redirectTo('/planos')}
                            >
                                <p onClick={() => redirectTo('/planos')}>ATUALIZAR PLANO</p>
                            </Upgrade>

                            <Userinfo
                                isBlackNovember={isBlackNovember}
                                showOptions={openUserOptions}
                                planId={planId}
                                updateOpenUserOptions={(e) => setOpenUserOptions(e)}
                                redirectTo={(e) => redirectTo(e, 10)}
                                location={props.location.pathname}
                            />
                        </>
                    ) : (null)}


                </>
            )}

            <Notification
                show={props.location.pathname === '/login' ?
                    false : props.location.pathname === '/compra/sucesso' ?
                        false : props.location.pathname === '/cadastro/sucesso' ?
                            false : props.location.pathname === '/cadastro' ?
                              false : props.location.pathname === '/validador' ?
                                false : props.location.pathname === '/cadastro/site' ?
                                    false : props.location.pathname === '/compendio-gratis' ?
                                        false : props.location.pathname.search(logincompra) !== -1 ?
                                            false : props.location.pathname === '/receituario-agronomico-2024' ?
                                               false : props.location.pathname === '/black-november-2024' ?
                                                    false : props.location.pathname.search(simulacaocompra) !== -1 ?
                                                        false : true
                }
                onClick={() => redirectTo('/notificacao')}
                isMobile={isMobile}
                isTest={isTest}
                isPro={planId == 5 ? true : false}
                tremer={qtd_notification > 0 ? true : false}
                isBlackNovember={isBlackNovember}
            >
                {qtd_notification > 0 ? (<NumNotifications>{qtd_notification}</NumNotifications>) : (null)}
                <IconNotification onClick={() => redirectTo('/notificacao')} />
            </Notification>

            <ModalTermosUso
                show={showModalTermo}
                btnOk={true}
                onHide={() => setShowModalTermo(!showModalTermo)}
            ></ModalTermosUso>

            <ModalPolitica
                show={showModalPolitica}
                btnOk={true}
                onHide={() => setShowModalPolitica(!showModalPolitica)}
            ></ModalPolitica>

            <ModalTextSession
                show={showModalSession}
                onHide={() => closeSession()}
                btnOk={true}
                text="Outra sessão foi iniciada em outro dispositivo."
                update={() => closeSession()}
            ></ModalTextSession>

            <ModalBloqueio
                show={showModalBloqueio}
                onHide={() => goToHome()}
                size='lg'
            >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                    <p
                        style={{
                            fontFamily: 'Lato',
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {titleModal}
                    </p>

                    <div
                        style={{
                            color: '#303848',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {messageModal}
                    </div>
                </div>
            </ModalBloqueio>

            <ModalBloqueio
                show={showModalBloqueioTesteGratis}
                onHide={() => goToHome()}
                size='md'
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column'
                    }}
                >
                    <p
                        style={{
                            fontFamily: 'Lato',
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {titleModal}
                    </p>

                    <div
                        style={{
                            color: '#303848',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {messageModal}
                    </div>

                </div>
            </ModalBloqueio>

            <ModalBloqueio
                show={showModalBloqueioLiberacao}
                onHide={() => goToHome()}
                size='mg'
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column'
                    }}
                >
                    <p
                        style={{
                            fontFamily: 'Lato',
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {titleModal}
                    </p>

                    <p
                        style={{
                            color: '#303848',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {messageModal}
                    </p>

                    <div
                        classname='items'
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}
                    >
                        {modalItems.map((i) => (
                            <p>{i}</p>
                        ))}
                    </div>

                    <div style={{ marginLeft: '10px', width: '100%', textAlign: 'center', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
                        <Button style={{ outline: 0, padding: "5px", width: "166px", height: "45px" }}
                            color="#00b43f"
                            onClick={() => goToHome()}
                        >ENTENDI</Button>
                    </div>
                </div>
            </ModalBloqueio>

            <ModalBloqueio
                show={showModalBloqueioPlanos}
                onHide={() => goToHome()}
                size='mg'
            >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                    <p
                        style={{
                            fontFamily: 'Lato',
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {titleModal}
                    </p>

                    <p
                        style={{
                            color: '#303848',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {messageModal}
                    </p>

                    <div
                        classname='items'
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}
                    >
                        {modalItems.map((i) => (
                            <p>{i}</p>
                        ))}
                    </div>

                    <div style={{ marginLeft: '10px', width: '100%', textAlign: 'center', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
                        <Button style={{ outline: 0, padding: "5px", width: "166px", height: "45px" }}
                            color="#00b43f"
                            onClick={() => goToHome()}
                        >ENTENDI</Button>
                    </div>
                </div>
            </ModalBloqueio>

            <ModalBloqueio
                show={showModalCancelado}
                onHide={() => goToHome()}
                size='mg'
            >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
                    <p
                        style={{
                            fontFamily: 'Lato',
                            fontSize: '22px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {titleModal}
                    </p>

                    <p
                        style={{
                            color: '#303848',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: 'normal',
                            width: '100%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        {messageModal}
                    </p>

                    <div
                        classname='items'
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}
                    >
                        {modalItems.map((i) => (
                            <p>{i}</p>
                        ))}
                    </div>

                    <div style={{ marginLeft: '10px', width: '100%', textAlign: 'center', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
                        <Button style={{ outline: 0, padding: "5px", width: "166px", height: "45px" }}
                            color="#00b43f"
                            onClick={() => goToHome()}
                        >ENTENDI</Button>
                    </div>
                </div>
            </ModalBloqueio>

            <ModalBloqueio
                show={showModalHoje}
                onHide={() => setShowHoje(false)}
                size='mg'
            >
                <>
                    <h2 style={styleTitleModal}>{`Seu teste grátis termina hoje`}</h2>
                    <p style={styleTextModal}> Utilize o cupom <b>TESTEGRATIS</b> e garanta já seu desconto! </p>
                    <p style={styleTextModal}> Planos Light e Pro com 50% de desconto na primeira mensalidade e Plano Pré Pago com 20% de desconto na sua primeira compra! </p>
                    <p style={styleTextModal}> Assine o Agroreceita para não perder os recursos abaixo: </p>
                    <p style={styleTextModal}> Emissão de receitas </p>
                    <p style={styleTextModal}> Controle de ARTs/TRTs</p>
                    <p style={styleTextModal}> Relatórios completos </p>
                    <p style={styleTextModal}> Envio de dados para as defesas agropecuárias e CREA SP </p>
                    <p style={styleTextModal}> Cadastro de produtores e locais de aplicação </p>
                    <p style={styleTextModal}> Cadastro de responsáveis técnicos </p>
                    <p style={styleTextModal}> Impressão e consulta de fichas de emergência e FISPQs </p>
                    <p style={styleTextModal}> Está com dúvidas sobre como usar nosso sistema? </p>
                    <p style={styleTextModal}> Agende uma <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/fale-com-especialista/', '_blank'); }}><u>consultoria gratuita!</u></span> </p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                        <ButtonV2
                            width='296px'
                            height='50px'
                            value='VEJA OS PLANOS'
                            outline={false}
                            disabled={false}
                            onClick={() => goToPlans()}
                            loading={false}
                        />
                    </div>
                </>
            </ModalBloqueio>


            <ModalGeneric
                show={showModalLogged}
                onHide={false}
                size='sm'
            >
                <div className="containerModal"
                    style={{
                        padding: '5px',
                        Width: "auto",
                        height: "auto"
                    }}
                >
                    <img src={Welcome} width="203" height="160" />

                    <h2 style={{
                        textAlign: 'center',
                        marginTop: '18px'
                    }}>Bem-vindo(a) ao AgroReceita!</h2>

                    <p style={{
                        textAlign: 'center',
                        marginTop: '18px',
                    }}>Aproveite todas as funções do <b>AgroReceita PRO</b> e melhore sua tomada de decisões no campo.</p>

                    <p style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        color: 'red'
                    }}>Nada será enviado às Defesas Agropecuárias durante o período de teste grátis. Explore tudo sem medo.</p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                        <ButtonV2
                            width='296px'
                            height='50px'
                            value='ENTENDI'
                            outline={false}
                            disabled={false}
                            onClick={() => setShowModalLogged(false)}
                            loading={false}
                        />
                    </div>
                </div>

            </ModalGeneric>

            <ModalGeneric
                show={showModalLoggedTrial && userLogado ? true : false}
                onHide={() => setShowModalLoggedTrial(false)}
                size='md'
            >
                {userLogado ? (
                    <>
                        <h2 style={styleTitleModal}>{`Seu teste grátis expira em ${userLogado.contrato.dias_restantes} dia(s)`}</h2>
                        <p style={styleTextModal}> Utilize o cupom <b>TESTEGRATIS</b> e garanta já seu desconto! </p>
                        <p style={styleTextModal}> Planos Light e Pro com 50% de desconto na primeira mensalidade e Plano Pré Pago com 20% de desconto na sua primeira compra! </p>
                        <p style={styleTextModal}> Assine o Agroreceita para não perder os recursos abaixo: </p>
                        <p style={styleTextModal}> Emissão de receitas </p>
                        <p style={styleTextModal}> Controle de ARTs/TRTs</p>
                        <p style={styleTextModal}> Relatórios completos </p>
                        <p style={styleTextModal}> Envio de dados para as defesas agropecuárias e CREA SP </p>
                        <p style={styleTextModal}> Cadastro de produtores e locais de aplicação </p>
                        <p style={styleTextModal}> Cadastro de responsáveis técnicos </p>
                        <p style={styleTextModal}> Impressão e consulta de fichas de emergência e FISPQs </p>
                        <p style={styleTextModal}> Está com dúvidas sobre como usar nosso sistema? </p>
                        <p style={styleTextModal}> Agende uma <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => { window.open('https://agroreceita.com.br/fale-com-especialista/', '_blank'); }}><u>consultoria gratuita!</u></span> </p>

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                            <ButtonV2
                                width='296px'
                                height='50px'
                                value='VEJA OS PLANOS'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                            />
                        </div>
                    </>
                ) : (null)}

            </ModalGeneric>
        </>
    )
}

export default withRouter(Navbar);
