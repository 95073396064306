import styled from "styled-components";

export const ContainerStepsAndPreview = styled.div` 
    width: 100%;
    height: auto;
    padding: 2rem 0rem;

    display: flex;
    align-items: center;
    flex-direction: column;

    >h2 {
        height: 6.563rem;

        color: #21262F;
        font-size: 2rem;
        font-weight: 800;
        line-height: 1.3em;
        font-family: "Lato", Sans-serif;
    }

    @media (max-width: 1024px) {
        padding: 2rem 2rem 0;

        >h2 {
            padding: 0 7rem;
            
            text-align: center;
            font-size: 1.75rem;
        }
    }

    @media (max-width: 767px) {
        padding: 0 0.5rem;

        >h2 {
            padding: 0;
            height: 5.938rem;

            font-size: 1.5rem;
        }
    }

    @media (max-width: 375px) {
        >h2 {
            height: auto;
            padding-bottom: 1rem;
        }
    }
`;

export const ContainerPreview = styled.div` 
    width: 100%;
    height: auto;

    gap: 2rem;
    display: flex;
    justify-content: center;

    @media (max-width: 1024px) {
        padding: 2rem 0;

        align-items: center;
        flex-direction: column-reverse;
    }
`;

export const ContainerPreviewVideo = styled.div`
    position: relative;

    width: 100%;
    height: 27.313rem;
    max-width: 48.438rem;

    display: flex;

  > iframe {
    top: 0;
    left: 0;
    position: absolute;

    width: 100%;
    height: 100%;
    max-width: 44.438rem;
    max-height: 24.75rem;

    border-radius: 1rem;
  }

  

  @media (max-width: 1024px) {
    height: 49.5vw;
    max-width: 100%;
    padding-left: 1rem;

    flex-direction: row-reverse;

    > iframe {
      position: relative;

      width: 82.4vw;
      height: 48vw;
      max-width: 52.188rem;
      max-height: 30.375rem;

      aspect-ratio: 16 / 9;
    }
  }

  @media (max-width: 767px) {
    padding-left: 1.5rem;

    >iframe{
        width: 85.4vw; 
    }
  }

  @media (max-width: 375px) {
    >iframe{
        width: 79.4vw;  
    }
  }
`;

export const ContainerImage = styled.div`
    width: 11vw;

    > img {
        right: 0;
        bottom: 0;
        z-index: 1;
        position: absolute;

        width: 10rem;

        border-radius: 1.5rem;
        transform: translate3d(0, 0, 0);
        transition: transform 0.1s ease-out;
        box-shadow: 0 0 2rem 0 rgba(33, 38, 47, 0.8);
    }

  @media (max-width: 1024px) {
    > img {
        left: 0;
        right: auto;
        bottom: 0.313rem;

        width: 16.1vw;
        height: 32.5vw;
    }
  }

  @media (max-width: 767px) {
    > img {
        left: 0.813rem;

        width: 12.55vw;
        height: 26.5vw;
     }
  }

  @media (max-width: 479px) {
    >img{
        border-radius: 0.5rem
    }
  }
`

export const ContainerSteps = styled.div` 
    width: 100%;
    height: auto;
    max-width: 21.25rem;

    @media (max-width: 1024px) {
        max-width: 100%;

        padding: 0 9rem;
    }

    @media (max-width: 767px) {
        padding: 0;
    }
`;

export const Steps = styled.div` 
    width: 100%;
    height: auto;

    gap: 1rem;
    display: flex;
    flex-direction: column;
    border-left: 0.25rem solid #2FBB69;

    >h3,
    p {
        padding-left: 1.5rem;
    }

    >b {
        font-weight: 700;
    }

    >h3 {
        color: #21262F;
        font-weight: 800;
        line-height: 1.2em;
        font-size: 0.875rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: "Lato", Sans-serif;
    }

    >p {
        color: #21262F;
        font-weight: 400;
        line-height: 1.5em;
        font-size: 0.875rem;
        font-family: "Lato", Sans-serif;
        margin-bottom: 1rem;
    }


    @media (max-width: 1024px) {
        >p:nth-of-type(1) {
            height: 3.625rem;
        }

        >p:nth-of-type(2) {
            height: 2.313rem;
        }
    }

    @media (max-width: 375px) {
        gap: 1.5rem;
    }
`;

export const ContainerDownload = styled.div` 
    width: 100%;
    height: auto;
    padding: 2rem 0 0 1.5rem;

    gap: 1rem;
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
        padding: 2rem 6.5rem 0 1.5rem;

        justify-content: space-between;

    }

    @media (max-width: 767px) {
        padding: 3rem 0 0;

        justify-content: center;

        >div:nth-of-type(1){
            display: none;
        }
    }
`;

export const DownloadApp = styled.div` 
    width: 7rem;
    height: auto;

    @media (max-width: 1024px) {
        width: auto;
    }
`;

export const ImgGooglePlay = styled.div` 
    display: flex;
    margin-bottom: 0.5rem;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    >a {
        width: 7rem;

        display: flex;
        justify-content: center;
    }

    img {
        width: 7rem;
        margin-top: 0.5rem;
    }

    a:hover {
        img {
            width: 7.5rem;
            transition: all 0.5s;
        }
    }


    @media (max-width: 1024px) {
        >a {
        width: auto;
    }

        img {
            width: 7.5rem;
        }
    }

    @media (max-width: 767px) {
        gap: 1rem;

        > a {
            width: 10.313rem;

            :hover {
                img {
                    width: 10.313rem;
                    transition: all 0.5s;
                }
            }
        }

        img {
            width: 9.375rem;
        }
    }
`;