import React from 'react';
import { withRouter } from "react-router-dom";

import RegistrationAndLogin from '../../components/registrationAndLogin';
import Carousel from '../../components/carousel';
import QualityTable from '../../components/qualityTable';
import StepsAndPreview from '../../components/stepsAndPreview';
import CarouselComments from '../../components/carousel-comments';


import * as S from './styles';
import PlanTable from '../../components/planTable';
import Footer from '../../components/footer';
import AnimatedCounter from '../../components/animated-counter';

export const compendioG = "@compendioG";

const ReceituarioAgronomico2024 = ({ ...props }) => {
    return (
        <S.Container>
            <S.Wrapper>
                <RegistrationAndLogin />

                <Carousel />

                <StepsAndPreview />

                <AnimatedCounter />

                <CarouselComments />

                <QualityTable />

                <PlanTable />

                <S.ContainerFooter>
                    <Footer />
                </S.ContainerFooter>
            </S.Wrapper>
        </S.Container>
    );
};

export default withRouter(ReceituarioAgronomico2024);
