import React from 'react';
import Button from "../button/button_v2.js";

import check from "../../assets/check-verde.png"
import * as S from './styles.js';
import { tableData } from './tableData.js';

function QualityTable() {
    return (
        <S.MainContainer>
            <S.TextContainer>
                <S.Heading>Por que escolher o AgroReceita?</S.Heading>
                <S.ContainerButton>
                    <a href='#teste-gratis'>
                        <Button
                            height='56px'
                            width='180px'
                            value='COMEÇAR AGORA'
                            outline={false}
                            disabled={false}
                            loading={false}
                        />
                    </a>
                </S.ContainerButton>
            </S.TextContainer>

            <S.ContainerTable>
                <S.Table>
                    {tableData.map((item) => (
                        <S.TableRow key={item.id}>
                            <S.TableCell>
                                <S.ImageTable src={item.src} />
                                <S.ContentWrapper>
                                    <S.TextTitle>{item.textTitle}</S.TextTitle>
                                    <S.TextDescription>{item.textDescription}</S.TextDescription>
                                </S.ContentWrapper>
                            </S.TableCell>
                            <S.TableCell>
                                <S.ImageVerify src={check} />
                            </S.TableCell>
                        </S.TableRow>))}
                </S.Table>
            </S.ContainerTable>
        </S.MainContainer>
    );
}

export default QualityTable;
